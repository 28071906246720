
import {ethNetwork,bscNetwork,baseNetwork,polygonNetwork,aptosNetwork,solNetwork,arbitrumNetwork,avalNetwork,cronosNetwork,fantomNetwork,algorandNetwork,nearNetwork,atomNetwork,stellarNetwork,tronNetwork,ozoneNetwork,suiNetwork,egonNetwork,


  PUBLIC,PRIVATE,WHITELIST,GUARANTEED,FCFS,LEVELS,DISTRIBUTION, cantoNetwork,IDORULES,SecuredIDOUrl,AutonomyIDOUrl,VESTINGTYPE} from './config';

export const projects  = [
  {
  "params":"torkpadpvt",
  "tokenName":"TorkPad",
  "tokenSymbol":"TPAD",
  "tokenDecimals":9,
  "tokenAddress":"0x77972A1BA3cAb14E1E907b58BBc4971544f4719d",
  "totalSupply":"100,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":0.0055,
  "listingPrice":0.007,
  "ath":"TBA",
  "minAllocation":"10BUSD",
  "maxAllocation":"2500BUSD",
  "currency":"BUSD",
  "logoUrl": "/assets/images/project/torkpad/logo.png",
  "bannerUrl": "/assets/images/project/torkpad/banner.png",
  "description":"TorkPad is a decentralized multi-chain fundraising platform that allows ventures to raise funds while also providing early stage investors with security.",
  "whitepaperUrl": "",
  "participent":"TBA",
  "hardCap":"27500",
  "softCap":"13750",
  "cap":27500,
  "saleType":PRIVATE,
  "blockchain":bscNetwork,
  "telegram": "https://t.me/TorkPad",
  "telegramAnn":"https://t.me/TorkPadAnn",
  "twitter": "https://twitter.com/TorkPad",
  "website": "https://torkpad.io",
  "linkedin": "",
  "medium": "https://TorkPad.medium.com",
  "reddit": "",
  "facebook": "",
  "discord": "",
  "whitepaperUrl": "https://docs.torkpad.io",
  "openTime":1668920472,
  "closeTime":1669352472,
  "listingTime":0,
  "totalRaised":27500,
  "saleAddress":"0xc278733FCc4D9E0D37e0505c288c3C65163549dE",
  "coingecko": "https://www.coingecko.com/en/coins/torkpad",
  "coinmarketcap": "https://coinmarketcap.com/currencies/torkpad/",
  "listingDetails":"ASAP at Pancakeswap",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":WHITELIST,
  "vesting": "10% at the TGE, then 15% on a monthly basis",
  "claimable":true,
  "vestingDetails":{"type":VESTINGTYPE[0],"claimAddress":"0xF0b9C31875b8D13C86F403255C3fD0Ca780bFd58"},//0x87AbF3d889940817DA5f1763A966b55A27eE1ABd
  "distribution":DISTRIBUTION[1],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":true,
},

{
  "params":"noxal",
  "tokenName":"The Noxal",
  "tokenSymbol":"OXA",
  "tokenDecimals":6,
  "tokenAddress":"ZSBbYvvVNLsdq6Ud8aoeeUdFDRYhWfbufeUDg6UeHwG",
  "totalSupply":"1,000,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":0.0065,
  "listingPrice":0.0075,
  "ath":0,
  "minAllocation":"50BUSD",
  "maxAllocation":"2000BUSD",
  "currency":"BUSD",
  "logoUrl": "/assets/images/project/noxal/logo.png",
  "bannerUrl": "/assets/images/project/noxal/banner.png",
  "description":"The Noxal is a Play&Earn game project built on Solana chain with the intergration of NFT collections in order to bring web3 gaming closer to millions of gamers around the world and promote game asset owner ship.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"25000",
  "cap":49937,
  "isPrivate":false,
  "saleType":PUBLIC,
  "blockchain":solNetwork,
  "telegram": "https://t.me/thenoxal",
  "twitter": "https://twitter.com/thenoxal",
  "website": "https://thenoxal.com/coming-soon",
  "medium": "",
  "discord": "https://discord.com/invite/9ghReAbRkf",
  "whitepaperUrl": "",
  "openTime":"1669528800",
  "closeTime":"1669615200",
  "listingTime":0,
  "totalRaised":49937,
  "saleAddress":"0xa1532617DfE5d3361Fc342C6cC2E46f1044189AD",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"10PM UTC 29th November, 2022",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "vesting": "30% TGE, cliff 14 days then linear vesting monthly 35%",
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false
},
{
  "params":"avadex",
  "tokenName":"Ava Dex Token",
  "tokenSymbol":"AVEX",
  "tokenDecimals":18,
  "tokenAddress":"",
  "totalSupply":10000000,
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":0.04,
  "listingPrice":0.04,
  "ath":0,
  "minAllocation":"100BUSD",
  "maxAllocation":"5000BUSD",
  "currency":"BUSD",
  "logoUrl": "/assets/images/project/avadex/logo.png",
  "bannerUrl": "/assets/images/project/avadex/banner.png",
  "description":"The first DEX on Avalanche with Mini-Games, Liquidity Mining and Trading!",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"25000",
  "cap":42900,
  "isPrivate":false,
  "saleType":PUBLIC,
  "blockchain":avalNetwork,
  "telegram": "https://t.me/ava_dex",
  "twitter": "https://twitter.com/avadex_io",
  "website": "https://avadex.io",
  "medium": "",
  "discord": "",
  "whitepaperUrl": "",
  "openTime":"1670047200",
  "closeTime":"1670133600",
  "listingTime":0,
  "totalRaised":42900,
  "saleAddress":"0x19b664D7a48e2bAC5b99e3523A5a6203aff2e224",
  "coingecko": "https://www.coingecko.com/en/coins/avadex-token",
  "coinmarketcap": "",
  "listingDetails":"4.00 PM UTC 14th December, 2022",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "vesting": "30% at TGE and 25% linearly vesting for 3 months",
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false
},
{
  "params":"mrdriver",
  "tokenName":"MrDriver",
  "tokenSymbol":"MDR",
  "tokenDecimals":18,
  "tokenAddress":"",
  "totalSupply":200000000,
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":0.1,
  "listingPrice":0.1,
  "ath":0,
  "minAllocation":"75BUSD",
  "maxAllocation":"2000BUSD",
  "currency":"BUSD",
  "logoUrl": "/assets/images/project/mrdriver/logo.png",
  "bannerUrl": "/assets/images/project/mrdriver/banner.png",
  "description":"MrDriver Project is the new age for the individual online transportation and logistics service industry based on NFT and web3 technology for the first time in the world.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"25000",
  "cap":7505,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "30% at TGE, then 25% monthly",
  "telegram": "https://t.me/MrDriver_Official",
  "twitter": "https://twitter.com/MDriverOfficial",
  "website": "https://www.mrdriver.co/",
  "medium": "",
  "discord": "",
  "whitepaperUrl": "",
  "openTime":"1669874400",
  "closeTime":"1669960800",
  "listingTime":0,
  "totalRaised":7505,
  "saleAddress":"0x4b2C26210F3F648578241Bfdb5f96583082F6aab",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"7th December, 2022",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false
}
,
{
  "params":"nftpt",
  "tokenName":"Nft Paintings",
  "tokenSymbol":"Nftpt",
  "tokenDecimals":18,
  "tokenAddress":"",
  "totalSupply":100000000000,
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":0.002,
  "listingPrice":0.006,
  "ath":0,
  "minAllocation":"50BUSD",
  "maxAllocation":"3000BUSD",
  "currency":"BUSD",
  "logoUrl": "/assets/images/project/nftpt/logo.jpeg",
  "bannerUrl": "/assets/images/project/nftpt/banner.png",
  "description":"NFT Paintings is the first company in the world which offers unique opportunities related to investing in valuable paintings. So far there have been a lot of serious issues related with painted artwork.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"25000",
  "cap":77348,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "100% at TGE",
  "telegram": "https://t.me/nftpaimtingsgroup",
  "twitter": "https://twitter.com/nftpaintingss",
  "website": "https://www.nftpt.io/",
  "medium": "",
  "discord": "",
  "whitepaperUrl": "",
  "openTime":"1674194400",
  "closeTime":"1674369200",
  "listingTime":0,
  "totalRaised":77348,
  "saleAddress":"0x2E5c2cACf5cd180DEdcEA5649d50319c882D6588",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"January 23, 2023 Will be listed on p2pb2b",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false
}
,
{
  "params":"treis",
  "tokenName":"Trellis Token",
  "tokenSymbol":"TREIS",
  "tokenDecimals":18,
  "tokenAddress":"",
  "totalSupply":100000000,
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":0.20,
  "listingPrice":0.25,
  "ath":0,
  "minAllocation":"100BUSD",
  "maxAllocation":"3000BUSD",
  "currency":"BUSD",
  "logoUrl": "/assets/images/project/trellis/logo.png",
  "bannerUrl": "/assets/images/project/trellis/banner.png",
  "description":"Trellis is an open-source multi-chain automated market maker (AMM) protocol for token swaps on Aptos and Sui networks. Trade anonymously. No registration. No signup. No login. No KYC needed.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"150000",
  "softCap":"25000",
  "cap":63448,
  "saleType":PUBLIC,
  "blockchain":aptosNetwork,
  "vesting": "40% TGE and 10% monthly for 6 month's",
  "telegram": "https://t.me/trellis_fi",
  "twitter": "https://twitter.com/trellis_fi",
  "website": "https://trellis.fi",
  "medium": "",
  "discord": "",
  "whitepaperUrl": "",
  "openTime":"1677045600",
  "closeTime":"1677218400",
  "listingTime":0,
  "totalRaised":63448,
  "saleAddress":"0x0972170a30712091a15EF3C3764B27F9d1c0b262",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"February 28, 2023 Will be listed on Pancakeswap, Bitfinex, p2pb2b",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false
},

{
  "params":"neocortexai",
  "tokenName":"CORAI",
  "tokenSymbol":"CORAI",
  "tokenDecimals":18,
  "tokenAddress":"0x17C8C198c06f16a8dB68148d9ac460F5aED029d8",
  "totalSupply":1000000000,
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":0.005,
  "listingPrice":0.005,
  "ath":0,
  "minAllocation":"100BUSD",
  "maxAllocation":"3000BUSD",
  "currency":"BUSD",
  "logoUrl": "/assets/images/project/neocortexai/logo.png",
  "bannerUrl": "/assets/images/project/neocortexai/banner.png",
  "description":"NeoCortexAI: Embracing the synergy of AI and blockchain, your portal to a brave new digital era, the ultimate access point.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"50000",
  "cap":80697,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "20 % at TGE - thereafter 3 months linear",
  "telegram": "https://t.me/NeoCortexAI",
  "twitter": "https://twitter.com/neocortexai",
  "website": "https://www.neocortexai.xyz/",
  "medium": "https://neocortexai.medium.com/",
  "discord": "https://discord.com/invite/hnyFPy8uAr",
  "whitepaperUrl": "https://docs.neocortexai.xyz/",
  "openTime":"1678860000",
  "closeTime":"1679032800",
  "listingTime":0,
  "totalRaised":80697,
  "saleAddress":"0xe40AD6010aAC44d218F518C507d357590EdEDd20",
  "coingecko": "https://www.coingecko.com/en/coins/neocortexai/",
  "coinmarketcap": "https://coinmarketcap.com/currencies/neocortexai/",
  "listingDetails":"March 21, 2023 Will be listed on Pancakeswap",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false
},
{
  "params":"alfprotocol",
  "tokenName":"Alfprotocol",
  "tokenSymbol":"ALF",
  "tokenDecimals":9,
  "tokenAddress":"36s9cAKFqea4gGFwAjG92Aoo3Zx5L3AbqBB519QeKsZU",
  "totalSupply":10000000000,
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":0.007,
  "listingPrice":0.007,
  "ath":0,
  "minAllocation":"50BUSD",
  "maxAllocation":"5000BUSD",
  "currency":"BUSD",
  "logoUrl": "/assets/images/project/alfprotocol/logo.png",
  "bannerUrl": "/assets/images/project/alfprotocol/banner.png",
  "description":"Alf is a protocol for capital deployment on Solana for the purposes of liquidity provision and yield farming, both with and without margin of up to 200x. The protocol introduces its own versions of an invariant-based Automated Market Maker protocol for exchange operations and a money market for short-term loans. The central contribution to the Solana ecosystem is a protocol for leveraged LP positions in AMM pools and yield farming protocols.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"50000",
  "cap":97223,
  "saleType":PUBLIC,
  "blockchain":solNetwork,
  "vesting": "20% Monthly for 5 months.",
  "telegram": "https://t.me/alfprotocol",
  "twitter": "https://twitter.com/AlfProtocol",
  "website": "https://alfprotocol.com",
  "medium": "https://medium.com/@alfprotocol",
  "discord": "https://discord.gg/WBmKUH4rk2",
  "whitepaperUrl": "",
  "openTime":"1679119200",
  "closeTime":"1679205600",
  "listingTime":0,
  "totalRaised":97223,
  "saleAddress":"0x414302aaB4C1E2c85823BB50063b7fBb0DDaA26c",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"March 22, 2023 Will be listed on MEXC",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false
}
,
{
  "params":"ccharge",
  "tokenName":"C+Charge",
  "tokenSymbol":"CCHG",
  "tokenDecimals":18,
  "tokenAddress":"0x24F2f371D74B25da7597AdEAe55895fe6B5c2FDE",
  "totalSupply":1000000000,
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":0.02,
  "listingPrice":0.0255,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/ccharge/logo.png",
  "bannerUrl": "/assets/images/project/ccharge/banner.png",
  "description":"C+Charge is the first on or off chain payment platform that rewards EV drivers (and token holders) with Carbon Credits, democratizing an industry that has been previously reserved for large corporates and conglomerates, bringing accessibility to these rewards, further incentivizing sustainable mobility.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"50000",
  "cap":50550.71,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "100% At TGE",
  "telegram": "https://t.me/+J09LEdPvFeRlOWYx",
  "twitter": "https://twitter.com/C_Charge_Token",
  "website": "https://c-charge.io/",
  "medium": "",
  "discord": "https://discord.gg/xtS3VYXbw5",
  "whitepaperUrl": "",
  "openTime":"1679896800",
  "closeTime":"1679983200",
  "listingTime":0,
  "totalRaised":50550.71,
  "saleAddress":"0xEc5ce52Ec50fddf3B59AaAD574D312aA0D803342",
  "coingecko": "c-charge",
  "coinmarketcap": "",
  "listingDetails":"March 31, 2023 Will be listed on Bitmart,Bkex,Lbank, (gate.io possibly)",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":true,
  "buyChart":true,
  "buyLinks":[{"icon":"/assets/img/bitmart.svg","name":"BitMart","description":"Buy CCHG","link":"https://www.bitmart.com/trade/en-US?layout=basic&theme=dark&symbol=CCHG_USDT"}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":"https://www.dextools.io/app/en/bnb/pair-explorer/0x2a592d0c8e2f516ea13c97db4c253edfc5131e44"}]

}
,
{
  "params":"klover",
  "tokenName":"Klover Network",
  "tokenSymbol":"KVN",
  "tokenDecimals":18,
  "tokenAddress":"",
  "totalSupply":289000000,
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":0.035,
  "listingPrice":0.045,
  "ath":0,
  "minAllocation":"100USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/klover/logo.jpg",
  "bannerUrl": "/assets/images/project/klover/banner.jpg",
  "description":"Klover Network is a web3 casino aggregator, empowering anyone to build a fair & transparent online casino.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"150000",
  "softCap":"30000",
  "cap":53632.58,
  "saleType":PUBLIC,
  "blockchain":arbitrumNetwork,
  "vesting": "100% at TGE",
  "telegram": "https://t.me/klovernetwork",
  "twitter": "https://twitter.com/klover_network",
  "website": "https://www.klover.network",
  "medium": "",
  "discord": "https://discord.gg/ygFWy2YkXF",
  "whitepaperUrl": "https://docs.klover.network/",
  "openTime":"1681452000",
  "closeTime":"1681624800",
  "listingTime":0,
  "totalRaised":53632.58,
  "saleAddress":"0x1a193719Bca99773f86FC52628C48461e5Abd5AB",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"April 18, 2023 Will be listed on Sushiswap, Uniswap",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false
}
,

{
  "params":"quantumhunter",
  "tokenName":"Quantum Hunter",
  "tokenSymbol":"QH",
  "tokenDecimals":18,
  "tokenAddress":"0x4fddA510590117860b9d3Db46EfB8DF002a831cE",
  "totalSupply":2000000000.00,
  "initialSupply":"TBA",
  "initialMarketCap":"$750,000",
  "initialFDV":"TBA",
  "idoPrice":0.0075,
  "listingPrice":0.00863,
  "ath":0,
  "minAllocation":"200USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/quantumhunter/logo.png",
  "bannerUrl": "/assets/images/project/quantumhunter/banner.png",
  "description":"Quantum Hunter is an AI-Generated Game with endless-possibility stories. Unlike the traditional game planned out by the game designer and completely predictable, through the AI model, we pool inputs together to generate unpredictable outputs, so that the Quantum Hunter storytelling possibilities are endless.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"30000",
  "cap":97276.57,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "Unlock 50% of TGE, then vested 25% in the next two months",
  "telegram": "https://t.me/QuantumHunterChannel",
  "twitter": "https://twitter.com/Quantum_Hunter_",
  "website": "https://quantumhunter.xyz/",
  "medium": "",
  "discord": "https://discord.gg/y9cRXtKw8J",
  "whitepaperUrl": "https://quantumhunter.xyz/wp-content/uploads/2023/03/Quantum-Hunter-White-Paper.pdf",
  "openTime":"1680760800",
  "closeTime":"1680933600",
  "listingTime":0,
  "totalRaised":97276.57,
  "saleAddress":"0xB9fc62376492EcdF2c95eef19ddae4146f6712c8",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"April 18, 2023 Will be listed on Bitmart, Pancakeswap",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false
}
,
{
  "params":"naviern",
  "tokenName":"Naviern",
  "tokenSymbol":"NVRN",
  "tokenDecimals":18,
  "tokenAddress":"0x0c4e5B36071b5019225934CfFA5f7834936790d4",
  "totalSupply":1000000000,
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":0.0075,
  "listingPrice":0.0075,
  "ath":0,
  "minAllocation":"200USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/naviern/logo.png",
  "bannerUrl": "/assets/images/project/naviern/banner.png",
  "description":"Naviern is a Web 3 Navigation and Ride-Share app built on the Binance Smart Chain network, with the goal of revolutionizing navigation and cryptocurrency rewards. Unlike other navigation apps, Naviern allows users to earn rewards tokens passively by using the app to navigate, which can be done through multiple modes of transportation, including walking, public transport, and driving. Each mode has its own ecosystem with different rewards calculations.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"25000",
  "cap":29685,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "10% TGE 9% mostly over next 9 month",
  "telegram": "https://t.me/linknaviern",
  "twitter": "https://twitter.com/naviernofficial",
  "website": "https://naviern.io/",
  "medium": "https://medium.com/@naviern",
  "discord": "",
  "whitepaperUrl": "https://naviern.gitbook.io/naviern-whitepaper/",
  "openTime":"1681365600",
  "closeTime":"1681452000",
  "listingTime":0,
  "totalRaised":29685,
  "saleAddress":"0x8365C3BdC5a54c472f70B93A57121fFBa68cDb2d",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"April 20, 09:00 UTC 2023 Will be listed on Lbank",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false
}
,
{
  "params":"evoliaprotocol",
  "tokenName":"EvoliaToken",
  "tokenSymbol":"EVO",
  "tokenDecimals":18,
  "tokenAddress":"",
  "totalSupply":300000000,
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":0.08,
  "listingPrice":0.08,
  "ath":0,
  "minAllocation":"100USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/evolia/logo.png",
  "bannerUrl": "/assets/images/project/evolia/banner.png",
  "description":"Evolia uses Artificial Intelligence algorithms to analyze social media and news sentiment towards different cryptocurrencies. We provide our users with insights and predictions to help them make more informed investment decisions.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"25000",
  "cap":29300,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "20% at TGE, 1 month cliff, 20% each month during 4 months",
  "telegram": "https://t.me/EvoliaProtocolChat",
  "twitter": "https://twitter.com/EvoliaProtocol",
  "website": "https://www.evoliaprotocol.net/",
  "medium": "https://evoliaprotocol.medium.com/",
  "discord": "",
  "whitepaperUrl": "https://www.evoliaprotocol.net/whitepaper/",
  "openTime":"1684735200",
  "closeTime":"1684908000",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0xD2a9F1dfe2304A9Cc425CF3a0B8A015ec0E39218",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"May 29, 2023",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false
},
{
  "params":"scratchmemecoin",
  "tokenName":"Scratch Meme Coin",
  "tokenSymbol":"SCRATS",
  "tokenDecimals":18,
  "tokenAddress":"TSZ1pV3SxYWUQ3yycFD7mC57X5CdzBHfoy",
  "totalSupply":100000000000000,
  "initialSupply":"TBA",
  "initialMarketCap":"375,000",
  "initialFDV":"TBA",
  "idoPrice":'0.000000015',
  "listingPrice":'0.000000025',
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"10000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/scratchmemecoin/logo.png",
  "bannerUrl": "/assets/images/project/scratchmemecoin/banner.png",
  "description":"Scratch Meme Coin was created based on the meme character Scrat from the movie Ice Age. We chose this character's meme because it fully symbolizes our team.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"75000",
  "softCap":"25000",
  "cap":74855,
  "saleType":PUBLIC,
  "blockchain":tronNetwork,
  "vesting": "100% at TGE",
  "telegram": "https://t.me/+tMBT508w2is5ZjFh",
  "telegramAnn":"https://t.me/scratchmemecoin",
  "twitter": "https://twitter.com/ScratchMemeCoin",
  "website": "https://scratchmemecpoin.com/",
  "medium": "",
  "discord": "",
  "whitepaperUrl": "",
  "openTime":"1686981600",
  "closeTime":"1687154400",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x999A24cd636B4d903Ec0e10A8886DeFf89058C9F",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"20th June, Will be listed on sunswap",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":true,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
}
,
{
  "params":"farmsent",
  "tokenName":"Farmsent",
  "tokenSymbol":"FARMS",
  "tokenDecimals":18,
  "tokenAddress":"0xf2da37643c25ca14291c2a69f51423ed9b7ff4b0",
  "totalSupply":10000000000,
  "initialSupply":"TBA",
  "initialMarketCap":"3000000",
  "initialFDV":"TBA",
  "idoPrice":0.03,
  "listingPrice":0.03,
  "ath":0,
  "minAllocation":"100USDT",
  "maxAllocation":"2500USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/farmsent/logo.png",
  "bannerUrl": "/assets/images/project/farmsent/banner.png",
  "description":"Farmsent is a blockchain platform connecting farmers with buyers, promoting food security and prioritizing small farmers. The decentralized marketplace records every step of the process, ensuring secure transactions, improving food safety and transparency.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"25000",
  "cap":50000,
  "saleType":PUBLIC,
  "blockchain":polygonNetwork,
  "vesting": "100% at TGE",
  "telegram": "https://t.me/farmsent",
  "instagram":"https://www.instagram.com/farmsent_io/",
  "twitter": "https://twitter.com/Farmsent_io",
  "website": "https://www.farmsent.io/",
  "medium": "https://medium.com/farmsent",
  "discord": "",
  "linkedin":"https://www.linkedin.com/company/farmsent/",
  "whitepaperUrl": "https://www.farmsent.io/assets/images/Farmsent-whitepaper.pdf",
  "openTime":1688450400,
  "closeTime":1688623200,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0xa30245B692021d678dddE730FC90be6aB6b07695",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"7th July, Coinstore",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
}
,
{
  "params":"ucfinance",
  "tokenName":"UC Finance",
  "tokenSymbol":"UCF",
  "tokenDecimals":18,
  "tokenAddress":"0xF44581d66Ff317D5D5307aa3235266F8C6694380",
  "totalSupply":1000000,
  "initialSupply":"TBA",
  "initialMarketCap":"600,000",
  "initialFDV":"TBA",
  "idoPrice":0.7,
  "listingPrice":1,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"10000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/ucfinance/logo.jpg",
  "bannerUrl": "/assets/images/project/ucfinance/banner.png",
  "description":"UC Finance is a cutting-edge financial project that aims to revolutionize the way people interact with traditional and decentralized finance. With its innovative and user-friendly platform, UC Finance offers a one-stop solution for all your financial needs, including banking, investing, lending, and trading. ",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"70000",
  "softCap":"30000",
  "cap":69899,
  "saleType":PUBLIC,
  "blockchain":polygonNetwork,
  "vesting": "100% at TGE",
  "telegram": "https://t.me/UCF_Global",
  "telegramAnn": "https://t.me/UCF_AN",
  "facebook":"https://facebook.com/stashaHQ",
  "instagram":"https://instagram.com/stashaHQ",
  "twitter": "http://twitter.com/UCFinance1",
  "website": "http://ucfinance.org",
  "medium": "",
  "discord": "",
  "whitepaperUrl": "",
  "openTime":"1686549600",
  "closeTime":"1686722400",
  "listingTime":0,
  "totalRaised":69899,
  "saleAddress":"0xD9D8B9BaF87b76C0B76c7A5c06FE3A130a1b008A",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"19th June, Sushiswap, Latoken, P2B, Bifinance",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
}
,
{
  "params":"tamakitty",
  "tokenName":"Tamakitty",
  "tokenSymbol":"TITTY",
  "tokenDecimals":18,
  "tokenAddress":"",
  "totalSupply":1000000000000,
  "initialSupply":"TBA",
  "initialMarketCap":"1,000,000",
  "initialFDV":"TBA",
  "idoPrice":0.000003125,
  "listingPrice":0.000008,
  "ath":0,
  "minAllocation":"10USDT",
  "maxAllocation":"3000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/tamakitty/logo.png",
  "bannerUrl": "/assets/images/project/tamakitty/banner.png",
  "description":"Welcome to the world of TAMAKITTY, a groundbreaking project built on the Tron Network initially that aims to revolutionize the concept of meme coins. In a world saturated with meme coins that often lack intrinsic value, TAMAKITTY sets itself apart by offering a meaningful experience to token holders. ",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"25000",
  "cap":31550,
  "saleType":PUBLIC,
  "blockchain":tronNetwork,
  "vesting": "20% unlock, 5 months vesting",
  "telegram": "https://t.me/tamakitty_official",
  "twitter": "https://twitter.com/tamakittycoin",
  "website": "https://www.tamakitty.club/",
  "medium": "",
  "discord": "",
  "whitepaperUrl": "https://www.tamakitty.club/whitepaper",
  "openTime":1687672800,
  "closeTime":1687845600,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0xB08Ad48580cCe414Fbf3245E6581cFbcEbe82a97",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"30th June, Poloniex",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":true,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
}
,
{
  "params":"ozonechain",
  "tokenName":"Ozone Chain",
  "tokenSymbol":"OZO",
  "tokenDecimals":18,
  "tokenAddress":"",
  "totalSupply":1000000000,
  "initialSupply":"TBA",
  "initialMarketCap":"60,000,000",
  "initialFDV":"TBA",
  "idoPrice":0.2,
  "listingPrice":0.22,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"2000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/ozonechain/logo.png",
  "bannerUrl": "/assets/images/project/ozonechain/banner.png",
  "description":"Ozone Chain is the world’s first quantum resistant blockchain that has integrated bleeding edge quantum security technologies in its design. Develop secure, scalable and user-friendly apps for mission critical domains.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"25000",
  "softCap":"15000",
  "cap":25000,
  "saleType":PUBLIC,
  "blockchain":ozoneNetwork,
  "vesting": "100% At TGE",
  "telegram": "https://t.me/ozonechainlabs",
  "twitter": "https://twitter.com/Ozone_chain",
  "website": "https://ozonechain.io/",
  "medium": "https://medium.com/@Ozone_chain",
  "discord": "https://discord.gg/xfG5rgQps3",
  "instagram":"https://www.instagram.com/ozone_chain/",
  "whitepaperUrl": "https://whitepaper.ozonechain.io/",
  "openTime":"1691992800",
  "closeTime":"1692079200",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0xcd9D87Fc455FFE5812430DA28d3D20286d93846A",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"18th August, BitMart, CoinStore, MEXC",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
}

,

{
  "params":"deelance",
  "tokenName":"Deelance",
  "tokenSymbol":"Dlance",
  "tokenDecimals":18,
  "tokenAddress":"0x7d60de2e7d92cb5c863bc82f8d59b37c59fc0a7a",
  "totalSupply":1000000000,
  "initialSupply":"TBA",
  "initialMarketCap":"8,000,000",
  "initialFDV":"TBA",
  "idoPrice":0.029,
  "listingPrice":0.057,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/deelance/logo.jpg",
  "bannerUrl": "/assets/images/project/deelance/banner.jpg",
  "description":"DeeLance - A Blockchain Ecosystem Where the World Works Together Smartly! The freelance industry is evolving, and DeeLance is at the forefront of the revolution. With our decentralized approach, we're connecting freelancers and recruiters, leveraging the power of blockchain technology.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"25000",
  "cap":49090,
  "saleType":PUBLIC,
  "blockchain":ethNetwork,
  "vesting": "33.3% TGE , remaining 2 month 33.3%",
  "telegram": "https://t.me/deelance_com",
  "twitter": "https://twitter.com/deelance_com",
  "website": "https://deelance.com/",
  "medium": "https://medium.com/@deeLance",
  "discord": "https://discord.gg/vhH3Sbt9NQ",
  "instagram":"https://instagram.com/deelanceofficial/",
  "whitepaperUrl": "https://docs.deelance.com/",
  "openTime":1690351200,
  "closeTime":1690524000,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x0c230482268A65709304F10A67738cBdEa898A83",
  "coingecko": "deelance",
  "coinmarketcap": "",
  "listingDetails":"7th August, uniswap, MeXc",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":true,
  "buyChart":true,
  "buyLinks":[{"icon":"/assets/img/uniswap.svg","name":"Uniswap","description":"Buy Dlance","link":"https://app.uniswap.org/swap?outputCurrency=0x7d60de2e7d92cb5c863bc82f8d59b37c59fc0a7a"}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":"https://www.dextools.io/app/en/ether/pair-explorer/0x600728d222cf77d883c8b7a55c7df64cec9f69da"}]
},
{
  "params":"360toolz",
  "tokenName":"Toolz",
  "tokenSymbol":"Toolz",
  "tokenDecimals":18,
  "tokenAddress":"",
  "totalSupply":1000000,
  "initialSupply":"TBA",
  "initialMarketCap":"33,000",
  "initialFDV":"TBA",
  "idoPrice":0.5,
  "listingPrice":0.55,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/360toolz/logo.png",
  "bannerUrl": "/assets/images/project/360toolz/banner.png",
  "description":"360Toolz is the ultimate AI platform that brings revolutionary solutions to the digital world. Whether you need top-notch SEO optimization, captivating content creation, seamless social media management and growth, or stunning graphic design, 360Toolz has you covered.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"25000",
  "cap":34250,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "10% at TGE, then 30% released after 48 hrs cliff, remaining 30% each month",
  "telegram": "",
  "twitter": "https://twitter.com/360Toolz",
  "website": "https://www.360toolz.com/",
  "medium": "",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "https://www.360toolz.com/_files/ugd/d31f2c_7a2c092498fa459a8b3a715ec6f7493d.pdf",
  "openTime":"1689832800",
  "closeTime":"1690005600",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0xD708e657e18C10AF1D89BAb5C266e58d0e071a9D",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"31st July, Pancakeswap",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
}
,
{
  "params":"momoerc",
  "tokenName":"MOMO V2",
  "tokenSymbol":"MOMO V2",
  "tokenDecimals":18,
  "tokenAddress":"",
  "totalSupply":1000000000000,
  "initialSupply":"TBA",
  "initialMarketCap":"400K",
  "initialFDV":"TBA",
  "idoPrice":0.0000018,
  "listingPrice":0.000002,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/momoerc/logo.png",
  "bannerUrl": "/assets/images/project/momoerc/banner.png",
  "description":"MOMO is a memecoin with utilities for continuous growth in Crypto World. Mission of MOMO Team is to keep building utilities and generate revenue for its holders to avoid getting the hype drained.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"25000",
  "cap":49963.53848,
  "saleType":PUBLIC,
  "blockchain":ethNetwork,
  "vesting": "100% at TGE",
  "telegram": "https://t.me/MOMOwhales_lounge",
  "telegramAnn": "https://t.me/momoerc_Ann",
  "twitter": "https://twitter.com/momoerc",
  "website": "https://app.momo-erc.com/",
  "medium": "",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "",
  "openTime":"1691647200",
  "closeTime":"1691820000",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x4d031F0E50FC127555297d15698fb20a40366642",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"25th August, UNISWAP/MOMOSWAP",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
}
,
{
  "params":"tokoqrt",
  "tokenName":"TokoQrt",
  "tokenSymbol":"TQRT",
  "tokenDecimals":9,
  "tokenAddress":"0x72b6887a46043c1299d3dae707893123d47cf73d",
  "totalSupply":100000000000,
  "initialSupply":"TBA",
  "initialMarketCap":"100K",
  "initialFDV":"TBA",
  "idoPrice":0.67,
  "listingPrice":5,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/tokoqrt/logo.png",
  "bannerUrl": "/assets/images/project/tokoqrt/banner.png",
  "description":"TokoQrt is an innovative exchange platform ecosystem that offers a range of features and services to its users. With the TQRT token at its core, the platform is designed to be highly liquid and versatile, making it a popular choice for traders and investors.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"25000",
  "cap":32320,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "10% at TGE Continuously each day total 10 days",
  "telegram": "https://t.me/qrkitaexchanger",
  "telegramAnn": "https://t.me/qrkitaexchange",
  "twitter": "https://twitter.com/qrkitaexchanger",
  "website": "https://tokoqrt.com",
  "medium": "",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "https://tome.app/pitchdeck-tokoqrt/tqrt-ido-plan-clfqird117z2e8i6syl8fqkos",
  "openTime":"1692424800",
  "closeTime":"1692597600",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x23e0B07d1845741a4617944C7Ce0030078830351",
  "coingecko": "",
  "coinmarketcap": "https://coinmarketcap.com/currencies/tokoqrt/",
  "listingDetails":"Softlaunch, Probit Global, Pancakeswap, w3swap, julswap, Biswap",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
}
,
{
  "params":"firepot",
  "tokenName":"HOTT",
  "tokenSymbol":"HOTT",
  "tokenDecimals":18,
  "tokenAddress":"0x14E8687Af6C94C7C68590bAcf0F1D1e338f2862B",
  "totalSupply":1000000000,
  "initialSupply":"TBA",
  "initialMarketCap":"400K",
  "initialFDV":"TBA",
  "idoPrice":0.005,
  "listingPrice":0.0055,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/firepot/logo.png",
  "bannerUrl": "/assets/images/project/firepot/banner.png",
  "description":"Firepot is a revolutionary DeFi project that aims to simplify DeFi and its usage among the world. Unlike traditional crypto projects with a core focus on only already existing web3 users, Firepot Finance seeks far and wide to reach and convert regular people familiar with CeFi systems. The vision of Firepot Finance to create an atmosphere for everyone, even moms with little technical knowledge to access DeFi is confidently backed by several mechanisms such as the AI copilot and Robo Advisory and Omni Chain dWallet. Doing things differently to attain results like never seen before is our motivation to keep the fire burning.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"25000",
  "cap":47270.8,
  "saleType":PUBLIC,
  "blockchain":arbitrumNetwork,
  "vesting": "15% at TGE 6 month",
  "telegram": "https://t.me/firepotfinancechat",
  "telegramAnn": "",
  "twitter": "https://twitter.com/FirepotFinance",
  "website": "https://www.firepot.finance/",
  "medium": "https://medium.com/@firepotfinance",
  "discord": "https://discord.com/invite/cyKqQ4VFTh",
  "instagram":"",
  "whitepaperUrl": "https://www.firepot.finance/_files/ugd/b908d5_f771257d227748769085f2929c479f7d.pdf",
  "openTime":"1693029600",
  "closeTime":"1693202400",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x60B8C0e7FAfE41DD06d199c9C001a23859ae873d",
  "coingecko": "firepot-finance",
  "coinmarketcap": "",
  "listingDetails":"8th September, Camelot",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":true,
  "buyLinks":[{"icon":"/assets/img/camelot.svg","name":"Camelot","description":"Buy HOTT","link":"https://app.camelot.exchange"}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":"https://www.dextools.io/app/en/arbitrum/pair-explorer/0xdb1ffe19a075f0ff73f6fe1f271f31a0c33b40fa"}]
}

,
{
  "params":"billionbox",
  "tokenName":"Billion Box",
  "tokenSymbol":"BBOX",
  "tokenDecimals":18,
  "tokenAddress":"0xF35Fddd1B23de477B64C5D8119E5135C2A575D84",
  "totalSupply":100000000000,
  "initialSupply":"TBA",
  "initialMarketCap":"1,050,000",
  "initialFDV":"TBA",
  "idoPrice":0.00003,
  "listingPrice":0.00003,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/billionbox/logo.png",
  "bannerUrl": "/assets/images/project/billionbox/banner.png",
  "description":"Billion Box is a decentralized lotto gaming platform that offers users the opportunity to win big rewards by participating in a range of exciting prediction-based games. Our platform uses blockchain technology and smart contracts to ensure that all games are transparent, secure, and tamper-proof. We have three main games, Decimal Box, the Prediction Box, BB Max, Billionbox Jackpot, and the Mystery Box, each with unique rules and rewards.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"25000",
  "cap":44400.58,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "15% TGE, 2 months cliff, 3 months vesting",
  "telegram": "https://t.me/billionboxgroup",
  "telegramAnn": "",
  "twitter": "https://twitter.com/Billionbox_io",
  "website": "https://billionbox.io/",
  "medium": "https://medium.com/@billionboxio",
  "discord": "https://discord.com/invite/8fatVAKyxG",
  "instagram":"",
  "whitepaperUrl": "https://info.billionbox.io/",
  "openTime":"1693807200",
  "closeTime":"1693980000",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x64F49f3eE7bE59E41e9B976553e338d5195ECa5a",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"11th September, Pancakeswap",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
}

,
{
  "params":"dinowars",
  "tokenName":"DINO WARS",
  "tokenSymbol":"DINW",
  "tokenDecimals":18,
  "tokenAddress":"",
  "totalSupply":50000000,
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":0.4,
  "listingPrice":0.4,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/dinowars/logo.png",
  "bannerUrl": "/assets/images/project/dinowars/banner.png",
  "description":"DinoWars is the F2P battle royal game where earnings are tied to the skills, not the donations, as in the usual pyramid. No need to buy NFT to earn money in the dinosaur universe!",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"30000",
  "softCap":"15000",
  "cap":29994.85,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "10% TGE, 6 months vesting",
  "telegram": "https://t.me/DinoWars_Game",
  "telegramAnn": "",
  "twitter": "https://twitter.com/DinoWars_Game",
  "website": "https://dino-wars.com/",
  "medium": "",
  "discord": "https://discord.gg/KySJJC298m",
  "instagram":"",
  "whitepaperUrl": "https://whitepaper.dino-wars.com/",
  "openTime":"1694757600",
  "closeTime":"1694747745",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x1ab1FCc4Aca476Cf8e677B5C753DB5a9dfCb64F4",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"19th September, Pancakeswap",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
}
,
{
  "params":"chavo",
  "tokenName":"CHAVO",
  "tokenSymbol":"CHA",
  "tokenDecimals":18,
  "tokenAddress":"0xa29685F043A89998eA18254e8E450Df989E13e2b",
  "totalSupply":88000000,
  "initialSupply":"TBA",
  "initialMarketCap":"$5M",
  "initialFDV":"TBA",
  "idoPrice":0.045,
  "listingPrice":0.05,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/chavo/logo.png",
  "bannerUrl": "/assets/images/project/chavo/banner.png",
  "description":"CHAVO is a token constructed on the Binance Smart Chain. Its primary mission is to reshape the Latin market by introducing a unique platform that facilitates seamless payments between merchants and cryptocurrency enthusiasts.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"25000",
  "cap":53799.25,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "20% At TGE, 20% monthly",
  "telegram": "https://t.me/+CL-h9LBbNfMzNjVh",
  "telegramAnn": "",
  "twitter": "https://twitter.com/CoinChavo",
  "website": "https://coinchavo.com/",
  "medium": "",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "",
  "openTime":"1694498400",
  "closeTime":"1694671200",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0xAa4380edbB526B1256c76658FA46f17d42fbfaf6",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"14th September, Pancakeswap",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
}


,
{
  "params":"gynaika",
  "tokenName":"GYNAIKA TOKEN",
  "tokenSymbol":"GAKA",
  "tokenDecimals":18,
  "tokenAddress":"0x9047dcF1d2D0056C1840150CA062d2b8DDEa89fC",
  "totalSupply":1000000000000,
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":0.00000833,
  "listingPrice":0.00001,
  "ath":0,
  "minAllocation":"100USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/gynaika/logo.png",
  "bannerUrl": "/assets/images/project/gynaika/banner.png",
  "description":"Gynaika is a web3 RPG developed on Unreal Engine 5.2. It is a video game that revolves around an engaging storyline and is linked to the Binance blockchain.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"25000",
  "cap":49962,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "60% At TGE and 10% monthly",
  "telegram": "https://www.t.me/gynaika_game",
  "telegramAnn": "",
  "twitter": "https://twitter.com/gynaika_game",
  "website": "https://www.gynaika.games/",
  "medium": "",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "https://www.gynaika.games/assets/docs/g1.pdf",
  "openTime":"1695276000",
  "closeTime":"1695448800",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x036a38c96a4c990D65bD1199ccEA320EC28911E3",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"28th September, Pancakeswap",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
}
,
{
  "params":"gamegem",
  "tokenName":"GameGem",
  "tokenSymbol":"GMGEM",
  "tokenDecimals":18,
  "tokenAddress":"",
  "totalSupply":100000000,
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":0.0425,
  "listingPrice":0.05,
  "ath":0,
  "minAllocation":"100USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/gamegem/logo.png",
  "bannerUrl": "/assets/images/project/gamegem/banner.png",
  "description":"Veggies Farm is a revolutionary Play-to-Earn Farm and Medieval Town simulator game. At Veggies Farm, we've been passionately crafting our gaming world for over 1.5 years. Relying on the Polygon $MATIC token has fueled our game's economy and allowed our dedicated player community to thrive and receive rewards.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"20000",
  "softCap":"12000",
  "cap":20000,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "20% At TGE and 20% monthly",
  "telegram": "https://t.me/veggiesfarm",
  "telegramAnn": "",
  "twitter": "https://twitter.com/info_nft",
  "website": "https://www.veggiesfarmgame.com/",
  "medium": "",
  "discord": "https://discord.gg/Es76JypHeG",
  "instagram":"",
  "whitepaperUrl": "",
  "openTime":"1696226400",
  "closeTime":"1696312800",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x49cD4Ae20f4411074cFafbE18FFbD1566c78a4cF",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"5th October, Pancakeswap",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
}
,
{
  "params":"vinuchain",
  "tokenName":"VinuChain",
  "tokenSymbol":"VC",
  "tokenDecimals":18,
  "tokenAddress":"0x2bf83d080d8bc4715984e75e5b3d149805d11751",
  "totalSupply":1000000000,
  "initialSupply":"TBA",
  "initialMarketCap":"$1,650,000",
  "initialFDV":"TBA",
  "idoPrice":0.025,
  "listingPrice":0.025,
  "ath":0.2216,
  "minAllocation":"42USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/vinuchain/logo.png",
  "bannerUrl": "/assets/images/project/vinuchain/banner.png",
  "description":"VinuChain is a cutting-edge blockchain ecosystem built on a DAG ledger structure, providing fast transactions, scalability, and most importantly, a determinably feeless experience. It rewards stakers for liquidity provision and supports smart contracts and decentralized applications. Discover the power of feeless transactions with VinuChain, redefining the way we interact with blockchain technology.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"25000",
  "cap":99999.35,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "100% at TGE",
  "telegram": "https://t.me/vitainu",
  "telegramAnn": "",
  "twitter": "https://twitter.com/vinuchain",
  "website": "https://vinuchain.org",
  "medium": "https://medium.com/@vitainu",
  "discord": "https://discord.gg/vinu",
  "instagram":"https://instagram.com/vinuchaindao",
  "whitepaperUrl": "https://vinu.gitbook.io/vinuchain/whitepaper/whitepaper",
  "openTime":"1696917600",
  "closeTime":"1697004000",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x8933A1aB434b73437D4E12384E64289e18a95a52",
  "coingecko": "vinuchain",
  "coinmarketcap": "",
  "listingDetails":"13th, Mexc, Gateio",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":true,
  "buyChart":true,
  "buyLinks":[{"icon":"/assets/img/pancakeswap.svg","name":"Pancakeswap","description":"Buy VC","link":"https://pancakeswap.finance/swap?outputCurrency=0x2Bf83D080d8Bc4715984e75E5b3D149805d11751"}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":"https://www.dextools.io/app/en/bnb/pair-explorer/0xc6dddcf94e53f8997dab999a32a3aa8fd3f7f106"}]
}
,
{
  "params":"evelon",
  "tokenName":"Evelon Token",
  "tokenSymbol":"EVLN",
  "tokenDecimals":18,
  "tokenAddress":"0xe37246e936A58099C7fcB8A08fa72900c4FAF991",
  "totalSupply":250000000,
  "initialSupply":"TBA",
  "initialMarketCap":"$95k",
  "initialFDV":"TBA",
  "idoPrice":0.06,
  "listingPrice":0.06,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/evelon/logo.jpg",
  "bannerUrl": "/assets/images/project/evelon/banner.png",
  "description":"Evelon is a no code platform that allows you to create and deploy dynamic NFTs with ease. This project is a game changer in the world of NFTs and image generation. Evelon uses AI to generate high-quality images, making it possible to create NFTs with unique visuals that are both dynamic and engaging.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"25000",
  "cap":32626.71,
  "saleType":PUBLIC,
  "blockchain":ethNetwork,
  "vesting": "20% TGE unlock, 4 months linear",
  "telegram": "https://t.me/evelonapp",
  "telegramAnn": "",
  "twitter": "https://twitter.com/EvelonApp",
  "website": "https://www.evelon.app/",
  "medium": "",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "https://docs.evelon.app/",
  "openTime":"1696658400",
  "closeTime":"1696744800",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0xb4abD441ccd00Dd93B9e1444E69d8f2C80C6e306",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"9 Oct 11.00 UTC, Pancakeswap, MEXC/BitMart",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
}
,
{
  "params":"moneydefiswap",
  "tokenName":"MoneyDeFiSwap Token",
  "tokenSymbol":"MONE",
  "tokenDecimals":18,
  "tokenAddress":"0x53516DfCa990E3895D044B97464EfFfd87df4040",
  "totalSupply":14653888888,
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":0.0003,
  "listingPrice":0.0005,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/moneydefiswap/logo.jpg",
  "bannerUrl": "/assets/images/project/moneydefiswap/banner.png",
  "description":"MoneydefiSwap is an innovative BEP-20 token that uses complex algorithms to generate automatic gas-less yield rewards, incentivizes holding while stabilizing the price through automatically generating liquidity, providing an unbreakable price floor. ",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"25000",
  "cap":33400,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "50% At TGE,15/15/20 % month",
  "telegram": "https://t.me/MoneydefiSwap",
  "telegramAnn": "",
  "twitter": "https://twitter.com/MoneydefiSwap",
  "website": "https://moneydefiswap.com/",
  "medium": "https://medium.com/@moneydefiswap",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "https://drive.google.com/file/d/1Zh6kJDNurlJafIugFKzRfNYpRqJ1qBv0/view?usp=drivesdk",
  "openTime":"1695967200",
  "closeTime":"1696140000",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x8Da09aF629Ebe8ce9dc18006dD673651F247ee23",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"3rd Oct 8:00 UTC, Pancakeswap, MEXC",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
}
,
{
  "params":"gvsglobalgroup",
  "tokenName":"GVS Token",
  "tokenSymbol":"GVS",
  "tokenDecimals":18,
  "tokenAddress":"0x9f68f166301aca720aa5b5588329230d3316e358",
  "totalSupply":500000000,
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":0.17,
  "listingPrice":0.2,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/gvsglobalgroup/logo.png",
  "bannerUrl": "/assets/images/project/gvsglobalgroup/banner.png",
  "description":"GVS Digital Asset Guarantee is a leading multinational company dedicated to providing comprehensive insurance solutions for a diverse range of assets, with a particular focus on digital assets such as NFTs and cryptocurrencies. Our mission is to safeguard the value of your investments and empower you to navigate the dynamic landscape of digital assets with confidence.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"30000",
  "cap":40300,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "40% At TGE, 10% monthly",
  "telegram": "https://t.me/GVSglobalgroup",
  "telegramAnn": "",
  "twitter": "http://twitter.com/GVSglobalgroup",
  "website": "https://gvsglobalgroup.com/",
  "medium": "",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "https://gvsglobalgroups-organization.gitbook.io/gvs-digital-asset-guarentee-whitepaper",
  "openTime":"1696399200",
  "closeTime":"1696528800",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x6621C97F0A51b84D6A7968a238aBB053327d28D4",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"12:00 UTC 8th Oct, Pancakeswap",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":true,
  "buyChart":true,
  "buyLinks":[{"icon":"/assets/img/pancakeswap.svg","name":"Pancakeswap","description":"Buy GVS","link":"https://pancakeswap.finance/swap?outputCurrency=0x9f68f166301aca720aa5b5588329230d3316e358"}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":"https://www.dextools.io/app/en/bnb/pair-explorer/0x1bf0c40816b6576940b86291fcd717cd085c9a24"}]
}
,
{
  "params":"propelcrypto",
  "tokenName":"PropelCrypto",
  "tokenSymbol":"PCR",
  "tokenDecimals":18,
  "tokenAddress":"0x264Cf2798c7Df1c86E5fe8164FE24aC7532bF624",
  "totalSupply":1000000000000000,
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":0.000001,
  "listingPrice":0.000002,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/propelcrypto/logo.png",
  "bannerUrl": "/assets/images/project/propelcrypto/banner.png",
  "description":"PropelCrypto (PCR) is an ERC20 token based on the Ethereum blockchain with a total supply of 1 quadrillion. Its purpose is to serve as Entrepreneurs’ Token in Africa, enabling them to hold, own, trade for profit and become the engine of empowering financially a Free Tuition Business School for 100% Entrepreneurship.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"60000",
  "softCap":"25000",
  "cap":46600,
  "saleType":PUBLIC,
  "blockchain":ethNetwork,
  "vesting": "20% at TGE then 20% every 30 days.",
  "telegram": "https://t.me/PropelCryptogroup",
  "telegramAnn": "https://t.me/PropelCryptochannel",
  "twitter": "https://twitter.com/PropelCryptoPCR",
  "website": "https://www.propelcrypto.online/",
  "medium": "https://medium.com/@propelcrypto",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "https://propelcrypto.online/wp-content/uploads/2023/05/PRC-whitepaper.pdf",
  "openTime":"1696226400",
  "closeTime":"1696312800",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x4c116020b874E59B00aE63255D95b96B9C727fE7",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"4th October, BankCex",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
}
,
{
  "params":"aicoinova",
  "tokenName":"AI Coinova",
  "tokenSymbol":"AICN",
  "tokenDecimals":18,
  "tokenAddress":"0x0D97d170cceE5c0DCd2B19ADC445613436581732",
  "totalSupply":20000000,
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":1.5,
  "listingPrice":3,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/aicoinova/logo.jpg",
  "bannerUrl": "/assets/images/project/aicoinova/banner.png",
  "description":"Coinova (AICN) is more than just a cryptocurrency; it's a blockchain ecosystem built on a foundation of decentralization, security, and innovation. At Coinova, our core values revolve around empowering users with the tools and knowledge they need to navigate the ever-evolving world of cryptocurrencies. ",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"25000",
  "cap":49849.79,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "100% At TGE",
  "telegram": "https://t.me/AI_CoiNova",
  "telegramAnn": "https://t.me/AI_CoiNova_Ann",
  "twitter": "https://twitter.com/ai_coinova",
  "website": "https://coinova.org/",
  "medium": "",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "https://coinova.org/whitepaper/AiCoinova%20WhitePaper.pdf",
  "openTime":"1696572000",
  "closeTime":"1696658400",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0xFB2Da6211C07091eB261247FD245810877166671",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"10th October, MEXC",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
}

,
{
  "params":"cinemakoin",
  "tokenName":"CinemaKoin",
  "tokenSymbol":"CK",
  "tokenDecimals":18,
  "tokenAddress":"0x7673551f5568f80115495997bb2c35191a408b99",
  "totalSupply":100000000,
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":1.15,
  "listingPrice":1.25,
  "ath":0,
  "minAllocation":"100USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/cinemakoin/logo.png",
  "bannerUrl": "/assets/images/project/cinemakoin/banner.png",
  "description":"CinemaKoin is a web3 conglomerate project focused on developing a one-stop solution catering to crypto trading, NFTs, Metaverse, and blockchain needs. We are building an entire ecosystem consisting of CinemaKoin, the cryptocurrency;",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"25000",
  "cap":46430.77,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "10% TGE & 10% Monthly",
  "telegram": "https://t.me/cinemakoinofficial",
  "telegramAnn": "",
  "twitter": "https://twitter.com/cinema_koin",
  "website": "https://www.cinemakoin.io/",
  "medium": "https://medium.com/@cinema_koin",
  "discord": "",
  "instagram":"https://instagram.com/cinemakoin",
  "whitepaperUrl": "https://cinemakoin.io/cinemakoin-litepaper.pdf",
  "openTime":"1697954400",
  "closeTime":"1698127200",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x2bD5EbA406FE2309072Ac61299d73e4715Aa01B8",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"10th Nov, Coinstore, Pancakeswap",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
}

,
{
  "params":"nbtc",
  "tokenName":"NewBitcoin",
  "tokenSymbol":"nBTC",
  "tokenDecimals":18,
  "tokenAddress":"0xC88634B0D4134b46fc3ec63184749bf95D325d0a",
  "totalSupply":500000000,
  "initialSupply":"TBA",
  "initialMarketCap":"568,750$",
  "initialFDV":"TBA",
  "idoPrice":0.001,
  "listingPrice":0.0035,
  "ath":0,
  "minAllocation":"20USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/nbtc/logo.png",
  "bannerUrl": "/assets/images/project/nbtc/banner.png",
  "description":"The Future of Payments - a universal payment coin The future of Digital currency; A Multichain new generation open source P2P currency,the new kind of money; King of payment tokens. nBTC(New kind of Bitcoin)... harnessing the potential of crypto payment with memes and tokens.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"70000",
  "softCap":"25000",
  "cap":70000,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "100% at TGE",
  "telegram": "https://t.me/nBTC_TG",
  "telegramAnn": "",
  "twitter": "https://x.com/_nBTC",
  "website": "https://nbtc.vercel.app/",
  "medium": "",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "https://gitinfo.gitbook.io/_nbtc/",
  "openTime":"1698213600",
  "closeTime":"1698386400",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x5b9284212c42409865E597c1459C9Cf413fbe207",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"27th Oct, Pancakeswap, gate and bitmart",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
}

,
{
  "params":"metalink",
  "tokenName":"MetaLink",
  "tokenSymbol":"M40",
  "tokenDecimals":18,
  "tokenAddress":"0x334872A33D01d0c7876E20CFBD8Eaf8080B3C796",
  "totalSupply":10000100000,
  "initialSupply":"TBA",
  "initialMarketCap":"250,000$",
  "initialFDV":"TBA",
  "idoPrice":0.00250,
  "listingPrice":0.00350,
  "ath":0,
  "minAllocation":"100USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/metalink/logo.png",
  "bannerUrl": "/assets/images/project/metalink/banner.png",
  "description":"META is the payment layer of Web3 that provides innovative solutions for the growing ecosystem of crypto, with a focus on micropayments. META infrastructure is designed to make it easy for people to use crypto in their daily lives.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"25000",
  "cap":66925,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "20% at TGE , 2  Months cliff and 6 months equal distribution",
  "telegram": "https://t.me/MetaLinkGroup",
  "telegramAnn": "",
  "twitter": "https://twitter.com/metalinkwallet",
  "website": "https://metalinkwallet.io/",
  "medium": "",
  "discord": "https://discord.com/invite/ane9Whef",
  "instagram":"",
  "whitepaperUrl": "https://metadocs.metalinkwallet.io/",
  "openTime":1699250400,
  "closeTime":1699423200,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x3F0Fa66c3f38242c907bA5C4AF024c30700de296",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"10th -12th November, Mexc , Bitmart",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
}
,

{
  "params":"quantumx",
  "tokenName":"Quantum X",
  "tokenSymbol":"QTX",
  "tokenDecimals":18,
  "tokenAddress":"0x57d0E37f5D0FfB04AA03cC8eC75448Ad56Fb40ca",
  "totalSupply":"10,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$15,000,000",
  "initialFDV":"TBA",
  "idoPrice":1,
  "listingPrice":1.5,
  "ath":0,
  "minAllocation":"30USDT",
  "maxAllocation":"10000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/quantumx/logo.png",
  "bannerUrl": "/assets/images/project/quantumx/banner.png",
  "description":"QuantumX is more than just another cryptocurrency; it represents a fundamental shift in the intersection of blockchain and artificial intelligence (AI). As technology evolves at an unprecedented pace, the QuantumX project emerges as a pioneering solution that harmonizes the power of decentralized ledgers with the intelligence of AI algorithms. Designed to transcend the limitations of traditional cryptocurrencies, QuantumX leverages cutting-edge technologies to offer a truly unique and transformative experience.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"30000",
  "cap":80092,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "100% at TGE",
  "telegram": "http://t.me/QuantumX_Official",
  "twitter": "https://x.com/QuantumX902",
  "website": "https://quantumx.news/",
  "medium": "",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "https://quantumx.news/QTX_paper.pdf",
  "openTime":1699855200,
  "closeTime":1699984800,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0xBd40Bfe84835Bd843A99Df052bEBb8C1192b978b",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"15th November, MEXC, CoinStore",
  "audit": false,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
},

/*
{
  "params":"owlit",
  "tokenName":"Owlit Finance",
  "tokenSymbol":"OWT",
  "tokenDecimals":18,
  "tokenAddress":"0x4c4f2be635f8f49ac68c84985226e805e0e70f9fcbc9ad8c841dbbe0c074015f",
  "totalSupply":"1,000,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$750,000",
  "initialFDV":"TBA",
  "idoPrice":0.01,
  "listingPrice":0.015,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/owlit/logo.png",
  "bannerUrl": "/assets/images/project/owlit/banner.png",
  "description":"Owlit is a comprehensive DEX platform within the Sui Ecosystem, primarily focused on enhancing the user experience and improving trading success rates. Its key features include a customizable interface for building personalized trade stations, real-time token price charts and technical indicators, social functions like private and ticker-based chat options, a award-winning AI-driven analysis tool (1st place in Bitcoin Magazine Competition), a trading robot for sentiment-based algorithmic trading, an innovative hourglass farming mechanism, and a pool-editor tool for adjusting black/whitelist and tax rates.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"30000",
  "cap":0,
  "saleType":PUBLIC,
  "blockchain":suiNetwork,
  "vesting": "30% at TGE, 10% linear on weekly basis thereafter",
  "telegram": "https://t.me/owlitcommunity",
  "twitter": "https://twitter.com/owlswap_finance",
  "website": "https://owlit.io/",
  "medium": "",
  "discord": "https://discord.gg/ZcX2UmN9",
  "instagram":"",
  "whitepaperUrl": "",
  "openTime":"TBA",
  "closeTime":"TBA",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"25th November, AscendEX, BitMart, Owlit",
  "audit": false,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
},
*/

{
  "params":"vefdefi",
  "tokenName":"VefDefi",
  "tokenSymbol":"VFI",
  "tokenDecimals":18,
  "tokenAddress":"0x319694649BFE27786961F6BE82548a305296C206",
  "totalSupply":"100,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$450,000",
  "initialFDV":"TBA",
  "idoPrice":0.0040,
  "listingPrice":0.0045,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/vefdefi/logo.png",
  "bannerUrl": "/assets/images/project/vefdefi/banner.png",
  "description":"VefDefi is a comprehensive decentralized finance (DeFi) ecosystem designed to revolutionize the way users interact with blockchain-based financial services. It encompasses a range of innovative features, such as DEX, Staking, Multisig, Bridge, Launchpad, NFT Marketplace and more. Building on VinuChain.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"25000",
  "cap":49999.4,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "100% at TGE",
  "telegram": "https://t.me/VefDefi",
  "twitter": "https://x.com/vefdefi",
  "website": "https://vefdefi.netlify.app",
  "medium": "https://medium.com/@VefDefi",
  "discord": "",
  "instagram":"https://Instagram.com/vefi.official",
  "whitepaperUrl": "https://vefdefi.gitbook.io/vefdefi/",
  "openTime":1700028000,
  "closeTime":1700200800,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x527E727f1cc207BA94Ce1EB9DE5f02d839511978",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"17th November, Pancakeswap",
  "audit": false,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
},

{
  "params":"hymenetwork",
  "tokenName":"HYME",
  "tokenSymbol":"HYME",
  "tokenDecimals":18,
  "tokenAddress":"0x4c61D70c0089D708040919aaC7BDd600Da72BC81",
  "totalSupply":"100,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$112,500",
  "initialFDV":"TBA",
  "idoPrice":0.012,
  "listingPrice":0.015,
  "ath":0,
  "minAllocation":"100USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/hymenetwork/logo.png",
  "bannerUrl": "/assets/images/project/hymenetwork/banner.png",
  "description":"HYME is the first scroll-to-find crypto search platform. With HYME, you can delve into the world of crypto projects with excitement and engagement. We believe that by presenting projects in a visually appealing and cinematic manner, we can make learning about cryptocurrencies more accessible, engaging, and entertaining. We are revolutionizing the way you explore and discover crypto projects by providing a seamless and immersive experience that brings the world of cryptocurrencies to your fingertips.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"60000",
  "softCap":"25000",
  "cap":40761.4,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "25% at TGE, 25% monthly",
  "telegram": "https://t.me/HYMEOfficial",
  "twitter": "https://twitter.com/HymeNetwork",
  "website": "https://hyme.network/",
  "medium": "https://medium.com/@hyme.network",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "https://hyme.gitbook.io/hyme-whitepaper",
  "openTime":1700978400,
  "closeTime":1701151200,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0xb8018116605D0bA3Bf92A4A61f7764A57Ff3676D",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"29th November, Pancakeswap",
  "audit": false,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
},
{
  "params":"worldchallengegame",
  "tokenName":"WorldToken",
  "tokenSymbol":"WORLD",
  "tokenDecimals":18,
  "tokenAddress":"0x63062d0F51fC65D4493c85DF0863f631FE2A26b6",
  "totalSupply":"21,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":0.95,
  "listingPrice":0.95,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/worldchallengegame/logo.png",
  "bannerUrl": "/assets/images/project/worldchallengegame/banner.png",
  "description":"World Challenge Game is an innovative fusion between a board game and a video game, enriched with Blockchain technology. This project aims to challenge your strategy and general culture knowledge. With each game box, you get a collectible digital item that becomes your avatar in the video game, allowing you to compete and socialize with people from all over the world through our app.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"25000",
  "cap":58350.4,
  "saleType":PUBLIC,
  "blockchain":polygonNetwork,
  "vesting": "20% TGE, 80% monthly vesting during 12 months.",
  "telegram": "https://t.me/worldchallengegame",
  "twitter": "https://twitter.com/WorldChallengeG",
  "website": "https://worldchallengegame.com/en",
  "medium": "",
  "discord": "https://discord.gg/JMSY67At3y",
  "instagram":"https://www.instagram.com/worldchallengegame/",
  "whitepaperUrl": "https://whitepaper.worldchallengegame.com/whitepaper/introduction/world-challenge-game",
  "openTime":1700460000,
  "closeTime":1700546400,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x6638A8e360Eb2fCCB4477ACD34257262c7222303",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"21th November 12:00 PM UTC,  WhiteBIT",
  "audit": false,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
},
{
  "params":"myra",
  "tokenName":"MYRA",
  "tokenSymbol":"MYRA",
  "tokenDecimals":18,
  "tokenAddress":"0xef33fd3d46C3EFa1804b513e3dDb7FdFf215DeF1",
  "totalSupply":"10,000,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$190,000",
  "initialFDV":"TBA",
  "idoPrice":0.0024,
  "listingPrice":0.0032,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/myra/logo.png",
  "bannerUrl": "/assets/images/project/myra/banner.png",
  "description":"Myra is a structure that aims to provide a unique experience to the users by integrating current technologies into the blockchain. Myra is a token because it belongs to a smart contract. Assets referred to as coins are those that have their own blockchain networks. MYRA aims to expand the usage areas of MYR worldwide by breaking new ground with its decentralized social media platform project. MYRA will expand its usage areas by producing projects in all areas of technology, without being bound by restrictions, and will grow in many sectors such as Blockchain and SocialFi.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"70000",
  "softCap":"25000",
  "cap":39101,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "20% TGE, 1 month cliff, 2 months linear vesting",
  "telegram": "http://t.me/myraofficialchat",
  "twitter": "https://twitter.com/myratoken",
  "website": "https://myratoken.com/",
  "medium": "http://medium.com/@myratoken",
  "discord": "",
  "instagram":"http://instagram.com/myratoken",
  "whitepaperUrl": "https://github.com/myratoken/Myra_Whitepaper/blob/main/Myra_Whitepaper.pdf",
  "openTime":1700805600,
  "closeTime":1700978400,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x6b24814F5264cA8010334Ae729EBdD52B969F948",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"27th November,  Toobit, Bitmart, LBank, MEXC",
  "audit": false,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
},
{
  "params":"demos",
  "tokenName":"Demos",
  "tokenSymbol":"DMS",
  "tokenDecimals":18,
  "tokenAddress":"0x7b8bc2d2eb93CB1DBcc9FF284eDC8dD8F481a43B",
  "totalSupply":"1,000,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$766,406",
  "initialFDV":"TBA",
  "idoPrice":0.0085,
  "listingPrice":0.0115,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/demos/logo.png",
  "bannerUrl": "/assets/images/project/demos/banner.png",
  "description":"Demos is an innovative mobile app that provides a direct connection between content creators and their audience, eliminating the need for middlemen.  With demos, you can now easily reach your desired audience without relying on subscriber limits or other traditional barriers.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"25000",
  "cap":32801,
  "saleType":PUBLIC,
  "blockchain":polygonNetwork,
  "vesting": "15% TGE, 2 month cliff, 6 months vesting",
  "telegram": "https://t.me/demosneteng",
  "twitter": "https://twitter.com/demosnetwork",
  "website": "https://demosapp.io",
  "medium": "",
  "discord": "",
  "instagram":"https://instagram.com/demos.inc?igshid=OGQ5ZDc2ODk2ZA==",
  "whitepaperUrl": "https://drive.google.com/file/d/1lnKZ3odw2ReL6uyhxnMbjRHIRkw6Sx9b/view?usp=sharing",
  "openTime":1701756000,
  "closeTime":1701842400,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x04c50e62A14FEcED3d26d2c6532B7a7d2602a1D4",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"13th December,  Uniswap",
  "audit": false,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
},
{
  "params":"gptplus",
  "tokenName":"GPTPlus",
  "tokenSymbol":"GPTPlus",
  "tokenDecimals":18,
  "tokenAddress":"",
  "totalSupply":"15,000,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$900,000",
  "initialFDV":"TBA",
  "idoPrice":0.0015,
  "listingPrice":0.002,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/gptplus/logo.png",
  "bannerUrl": "/assets/images/project/gptplus/banner.png",
  "description":"GPT Plus is about leveraging advanced AI capabilities to enhance user experiences and streamline various digital processes",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"25000",
  "cap":89583.56,
  "saleType":PUBLIC,
  "blockchain":ethNetwork,
  "vesting": "100% At TGE",
  "telegram": "https://t.me/GPTPlusChat",
  "twitter": "https://x.com/GPTPlusAI",
  "website": "https://gpt-plus.io/en/",
  "medium": "https://medium.com/@GPTPlus",
  "discord": "https://discord.gg/PjReVXvh5R",
  "instagram":"",
  "whitepaperUrl": "https://whitepaper.gpt-plus.io/",
  "openTime":1702101600,
  "closeTime":1702274400,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x5083d23106b018B9f8c61C0676f7Cf3588448bC5",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"14th December, MEXC",
  "audit": false,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
},
{
  "params":"cicca",
  "tokenName":"Cicca Defi Network",
  "tokenSymbol":"CICCA",
  "tokenDecimals":18,
  "tokenAddress":"0xe4a1a0F812eB343E68E5E0eF1883A8196e6Ec342",
  "totalSupply":"10,000,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":0.0002,
  "listingPrice":0.0002,
  "ath":0,
  "minAllocation":"100USDT",
  "maxAllocation":"3000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/cicca/logo.png",
  "bannerUrl": "/assets/images/project/cicca/banner.png",
  "description":"CiccaDefi Network is AI Powered staking protocol, Metaverse, Web3Games Nft Minting ,AI chat, & more!",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"25000",
  "cap":27598,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "10% at TGE ,10% FOR 1 month then 20% Monthly",
  "telegram": "https://t.me/CiccaOfficial",
  "twitter": "https://twitter.com/CiccaDefi",
  "website": "https://www.cicca.io/",
  "medium": "",
  "discord": "",
  "instagram":"https://www.instagram.com/ciccadefi",
  "whitepaperUrl": "https://files.cicca.io/whitepaper.pdf",
  "openTime":1701842400,
  "closeTime":1701928800,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x1d187bbD4272372d7b21e2F65427E15219A760eD",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"11th December, Pancakeswap",
  "audit": false,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
},
{
  "params":"satoshiair",
  "tokenName":"Satoshi Airline Token",
  "tokenSymbol":"SAT",
  "tokenDecimals":18,
  "tokenAddress":"0x17EB7C08C4057B6c270dc0549745adBc874Eb15B",
  "totalSupply":"500,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$772,500",
  "initialFDV":"TBA",
  "idoPrice":0.035,
  "listingPrice":0.05,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/satoshiair/logo.png",
  "bannerUrl": "/assets/images/project/satoshiair/banner.png",
  "description":"Satoshi Airlines will be a web3 gamified fly-to-earn ecosystem in which users can travel by air while being rewarded at the same time.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"25000",
  "cap":48102.2,
  "saleType":PUBLIC,
  "blockchain":arbitrumNetwork,
  "vesting": "30% tge, 4month vesting",
  "telegram": "https://t.me/satoshiairlines",
  "twitter": "https://twitter.com/Satoshiairlines",
  "website": "https://satoshiair.xyz/",
  "medium": "https://medium.com/@satoshiairlines",
  "discord": "https://discord.gg/7pVTcHYHn7",
  "instagram":"",
  "whitepaperUrl": "",
  "openTime":1701669600,
  "closeTime":1701756000,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x82b01E099605Ca06b4C8522457C09ab30B660CC4",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"7th December, UNISWAP",
  "audit": false,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  //4dec
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
},






{
  "params":"paisapad",
  "tokenName":"PaisaPad",
  "tokenSymbol":"PPD",
  "tokenDecimals":18,
  "tokenAddress":"0x62a997aa60d7b00c85d44ba12387107ec62b3ca9",
  "totalSupply":"1,000,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":'0.05',
  "listingPrice":'0.05',
  "ath":0,
  "minAllocation":"30USDT",
  "maxAllocation":"3000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/paisapad/logo.png",
  "bannerUrl": "/assets/images/project/paisapad/banner.png",
  "description":"PaisaPad is web3 crypto community sharing platform fosters an environment where users can freely share their thoughts, ideas, and creativity without being subject to censorship or ambiguous community standards. We firmly believe that every individual's voice deserves to be heard.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"25000",
  "cap":37700,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "25% At TGE, and remaining 3 months vesting",
  "telegram": "https://t.me/paisapad",
  "twitter": "https://twitter.com/paisapadtoken",
  "website": "https://paisapad.com/",
  "medium": "https://medium.com/paisapad",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "https://paisapad.com/wp-content/uploads/2023/12/PaisaPad-Pitch-Deck-1.pdf",
  "openTime":1702620000,
  "closeTime":1702706400,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0xA30D16e4786cb5C66256A69a9BF57112C7E6c485",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"16th December, Latoken,Fameex,Dex Trade,Toobit and Tindex.",
  "audit": false,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
},

{
  "params":"volleytoken",
  "tokenName":"volley",
  "tokenSymbol":"VOY",
  "tokenDecimals":18,
  "tokenAddress":"0x7f7158c1f5898523bb6869910847e83b84a7c8d2",
  "totalSupply":"1,000,000,000,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$7,000,000",
  "initialFDV":"TBA",
  "idoPrice":'0.000000007',
  "listingPrice":'0.000000007',
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/volleytoken/logo.png",
  "bannerUrl": "/assets/images/project/volleytoken/banner.png",
  "description":"Volley Token's mission is to give people seamless access to decentralized financial services through our Volleyswap platform. ⚡️The platform connects to various centralized and decentralized platforms to execute buy orders at the lowest available price and sell orders at the highest available price for the chosen trading pairs, ensuring optimal returns for users within the DeFi ecosystem.👉All in one secure and unified environment, without the need for any intermediary.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"25000",
  "cap":29290,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "20% at TGE, 2 months cliff then 6 months linear distribution",
  "telegram": "https://t.me/Volleytoken",
  "twitter": "https://twitter.com/Volleytoken",
  "website": "https://www.volleytoken.io/",
  "medium": "https://medium.com/@volleytoken",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "",
  "openTime":1702706400,
  "closeTime":1702792800,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x1c6B15fF7201c1Aa16AAc683b6C17e6e17334c12",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"20th December, Mexc, Hotscoin",
  "audit": false,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
},

{
  "params":"smay",
  "tokenName":"Smay",
  "tokenSymbol":"SMAY",
  "tokenDecimals":18,
  "tokenAddress":"",
  "totalSupply":"10,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":0.1,
  "listingPrice":0.15,
  "ath":0,
  "minAllocation":"100USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/smay/logo.png",
  "bannerUrl": "/assets/images/project/smay/banner.jpg",
  "description":"Welcome to Smay finance, a crypto DeFi Yield-farming project focused on establishing the largest treasury within the Canto Network. Our goal is straightforward: strategic utilization of funds through meticulously designed smart contracts. We prioritize transparency, security, and efficiency in our approach to decentralized. finance.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"25000",
  "cap":43200,
  "saleType":PUBLIC,
  "blockchain":cantoNetwork,
  "vesting": "10% TGE, 3 months vesting",
  "telegram": "",
  "twitter": "https://twitter.com/smayfinance",
  "website": "https://smay.finance/",
  "medium": "",
  "discord": "https://discord.gg/bcE249Dq",
  "instagram":"",
  "whitepaperUrl": "https://docs.smay.finance/",
  "openTime":1702792800,
  "closeTime":1702879200,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x0a96172cf1A9461420bC4b4ff397cc11a1b7C8eD",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"24th December, Velocimeter Dex",
  "audit": false,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
},



{
  "params":"novastar",
  "tokenName":"Nova Star",
  "tokenSymbol":"NVS",
  "tokenDecimals":18,
  "tokenAddress":"0x8e6dA437ab282bc7433B0918fa53A8cF32f83294",
  "totalSupply":"25,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":'0.5',
  "listingPrice":'0.8',
  "ath":0,
  "minAllocation":"10USDT",
  "maxAllocation":"10000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/novastar/logo.png",
  "bannerUrl": "/assets/images/project/novastar/banner.png",
  "description":"Nova Star is not just a cryptocurrency; it's a revolutionary platform designed to redefine the way we think about finance. With a focus on security, efficiency, and inclusivity, Nova Star aims to provide users with a seamless and empowering experience in the world of decentralized finance (DeFi).",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"25000",
  "cap":67650,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "100% At TGE",
  "telegram": "https://t.me/NovaStar_Global",
  "twitter": "https://x.com/nova_star_x",
  "website": "http://novastar.news/",
  "medium": "",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "http://novastar.news/NovaStarWP.pdf",
  "openTime":1702879200,
  "closeTime":1702965600,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x05e5DdC4EA26A3E7df726b853fFBE1D8F1E73d6A",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"24th December, MEXC,CoinW",
  "audit": false,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
},



{
  "params":"smilestack",
  "tokenName":"SmileStack",
  "tokenSymbol":"SST",
  "tokenDecimals":18,
  "tokenAddress":"0x26908b93E4122FCE058394D90AcB75583C25c829",
  "totalSupply":"10,000,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"6M",
  "initialFDV":"TBA",
  "idoPrice":'0.005',
  "listingPrice":'0.006',
  "ath":0,
  "minAllocation":"10USDT",
  "maxAllocation":"10000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/smilestack/logo.png",
  "bannerUrl": "/assets/images/project/smilestack/banner.png",
  "description":"SmileStack is not just a cryptocurrency; it's an all-encompassing ecosystem transforming the digital landscape. Comprising an AI Bot, a dedicated app, an NFT marketplace, and an upcoming AI blockchain, SmileStack offers a seamless and entertaining experience for crypto enthusiasts.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"25000",
  "cap":53985.38,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "20% at TGE, remaining 20-20% for the next 4 months.",
  "telegram": "https://t.me/SmileStackChannel",
  "twitter": "https://twitter.com/smilestacktoken",
  "website": "https://www.smilestackai.com/",
  "medium": "",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "https://smilestacks01s-organization.gitbook.io/smilestackai/",
  "openTime":1703224800,
  "closeTime":1703397600,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x07C292662820c07718A851837446cF9af5b23de6",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"25th December, Lbank",
  "audit": false,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
},





{
  "params":"torkpad",
  "tokenName":"TorkPad",
  "tokenSymbol":"TPAD",
  "tokenDecimals":18,
  "tokenAddress":"0x77972A1BA3cAb14E1E907b58BBc4971544f4719d",
  "totalSupply":"100,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"TBA",
  "idoPrice":0.0060,
  "listingPrice":0.007,
  "ath":"TBA",
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/torkpad/logo.png",
  "bannerUrl": "/assets/images/project/torkpad/banner.png",
  "description":"TorkPad is a decentralized multi-chain fundraising platform that allows ventures to raise funds while also providing early stage investors with security.",
  "whitepaperUrl": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"25000",
  "cap":21834,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "telegram": "https://t.me/TorkPad",
  "telegramAnn":"https://t.me/TorkPadAnn",
  "twitter": "https://twitter.com/TorkPad",
  "website": "https://torkpad.io",
  "linkedin": "",
  "medium": "https://TorkPad.medium.com",
  "reddit": "",
  "facebook": "",
  "discord": "",
  "whitepaperUrl": "https://docs.torkpad.io",
  "openTime":1703743200,
  "closeTime":1703916000,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x45118AE5af0eAA1F0Df67D357a85EBFd98bFBEe8",
  "coingecko": "torkpad",
  "coinmarketcap": "torkpad",
  "listingDetails":"January 1st, Pancakeswap",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "vesting": "100% At TGE",
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":true,
  "protection":true,
},
{
  "params":"nitroken",
  "tokenName":"Nitroken",
  "tokenSymbol":"NITO",
  "tokenDecimals":18,
  "tokenAddress":"0x472935591f64a09C5622eed08C51707a6640c86a",
  "totalSupply":"500,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$210,000",
  "initialFDV":"TBA",
  "idoPrice":'0.08',
  "listingPrice":'0.1',
  "ath":0,
  "minAllocation":"25USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/nitroken/logo.png",
  "bannerUrl": "/assets/images/project/nitroken/banner.png",
  "description":"Nitroken is an organization that aims to provide solutions to the problems of centralized structures by producing blockchain-based DEFI and SocialFi projects, and is on its way to becoming one of the leading structures in the sector with its decentralized applications.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"75000",
  "softCap":"25000",
  "cap":38199.82,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "10% TGE, 1 month cliff, 3 month linear vesting",
  "telegram": "https://t.me/nitrokenchat",
  "twitter": "https://twitter.com/nitroken2023",
  "website": "https://nitroken.com/",
  "medium": "https://nitroken.medium.com",
  "discord": "",
  "instagram":"https://www.instagram.com/nitrokenofficial/",
  "whitepaperUrl": "https://github.com/nitrokenofficial/Whitepaper/blob/main/NITROKEN_WHITEPAPER.pdf",
  "openTime":1705039200,
  "closeTime":1705212000,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0xb8D0661dD26cd71A0105fd9cBb116e8803f17674",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"15th January, P2B, Coinstore",
  "audit": false,
  "kyc": false,
  "fcfs":"1705194000",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":LEVELS,
  "claimable":false,
  "distribution":DISTRIBUTION[1],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "protectionType":IDORULES[1],
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
},
{
  "params":"betf",
  "tokenName":"BETF",
  "tokenSymbol":"BETF",
  "tokenDecimals":18,
  "tokenAddress":"0x2399EC8c5E34f83F24c43f8b0a02616fF0ad5525",
  "totalSupply":"21,000,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$8.4M",
  "initialFDV":"TBA",
  "idoPrice":0.04,
  "listingPrice":0.05,
  "ath":"TBA",
  "minAllocation":"10USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/bitcoinetf/logo.png",
  "bannerUrl": "/assets/images/project/bitcoinetf/banner.png",
  "description":"Bitcoin ETF Token. The exclusive crypto that incentivizes its holders with rewards as Bitcoin ETFs receive approval.",
  "whitepaperUrl": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"25000",
  "cap":29318.80,
  "saleType":PUBLIC,
  "blockchain":avalNetwork,
  "telegram": "https://t.me/BTC_ETF_Community",
  "telegramAnn":"https://t.me/BTC_ETF_Token",
  "twitter": "https://x.com/bitcoinetf_t",
  "website": "https://bitcoinetf.support/",
  "linkedin": "",
  "medium": "",
  "reddit": "",
  "facebook": "",
  "discord": "",
  "whitepaperUrl": "",
  "openTime":1705039200,
  "closeTime":1705125600,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0xb75ca2D2e8703091AB021Dd005A27E4C6CD848A6",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"February 9th, MEXC",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "vesting": "50% at TGE, 10% every month for next 5 Months",
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "protectionType":IDORULES[0],
},

/*
{
  "params":"testerai",
  "tokenName":"Tester AI",
  "tokenSymbol":"TAI",
  "tokenDecimals":18,
  "tokenAddress":"0x9B2d1921A2235E81635D0E56667ee43235b3bb2b",
  "totalSupply":"1,000,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$4M",
  "initialFDV":"TBA",
  "idoPrice":0.015,
  "listingPrice":0.025,
  "ath":"TBA",
  "minAllocation":"100USDT",
  "maxAllocation":"3000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/testerai/logo.png",
  "bannerUrl": "/assets/images/project/testerai/banner.png",
  "description":"TESTER utility token for detecting errors and vulnerabilities in the program code and their optimization and correction using #AItools #TesterAI",
  "whitepaperUrl": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"25000",
  "cap":0,
  "saleType":PUBLIC,
  "blockchain":ethNetwork,
  "telegram": "",
  "telegramAnn":"https://t.me/testerai_news",
  "twitter": "https://twitter.com/testerAI_",
  "website": "https://tester-ai.com/",
  "linkedin": "https://www.linkedin.com/company/tester-ai/",
  "medium": "",
  "reddit": "",
  "facebook": "",
  "discord": "",
  "whitepaperUrl": "http://tester-ai.gitbook.io/",
  "openTime":"TBA",
  "closeTime":"TBA",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"TBA",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":LEVELS,
  "vesting": "20% At TGE, 20% every month for next 4 Months",
  "claimable":false,
  "distribution":DISTRIBUTION[1],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "protectionType":IDORULES[0],
},
*/
{
  "params":"egoncoin",
  "tokenName":"EgonCoin",
  "tokenSymbol":"EGON",
  "tokenDecimals":18,
  "tokenAddress":"",
  "totalSupply":"271,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$2M",
  "initialFDV":"TBA",
  "idoPrice":'0.25',
  "listingPrice":'0.25',
  "ath":0,
  "minAllocation":"100USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/egoncoin/logo.png",
  "bannerUrl": "/assets/images/project/egoncoin/banner.png",
  "description":"EgonCoin (EGON) is a cryptocurrency with own native blockchain network using all new Elevated Proof of Stake (EPoS) consensus. EgonCoin native token standard EGC-20 is used to enable transactions on the blockchain, while standard for EgonCoin NFTs is EGC-721.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"25000",
  "cap":52899.81,
  "saleType":PUBLIC,
  "blockchain":egonNetwork,
  "vesting": "100% At TGE",
  "telegram": "https://t.me/eaglenetworkapp",
  "twitter": "https://twitter.com/eaglenetworkapp",
  "website": "https://egoncoin.com/",
  "medium": "",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "",
  "openTime":1707717600,
  "closeTime":1707890400,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0xa544459602dC1E439eB7C46E993bB50f194F3131",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"14th February, Coinstore",
  "audit": false,
  "kyc": false,
  "fcfs":"1707872400",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":LEVELS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "protectionType":IDORULES[1],
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
},
{
  "params":"brainstarter",
  "tokenName":"BRAINS",
  "tokenSymbol":"BRAINS",
  "tokenDecimals":18,
  "tokenAddress":"",
  "totalSupply":"2,100,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$1,100,000",
  "initialFDV":"TBA",
  "idoPrice":'0.012',
  "listingPrice":'0.012',
  "ath":0,
  "minAllocation":"100USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/brainstarter/logo.png",
  "bannerUrl": "/assets/images/project/brainstarter/banner.png",
  "description":"BrainStarter is a new quality standard in the process of transformation and support of startups from the web2 to web3 space. We call it Down-To-Earth. We mainly focus on new projects that use new technologies to solve real problems. Their common component is a real and working product that is scalable globally.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"25000",
  "cap":18230,
  "saleType":PUBLIC,
  "blockchain":polygonNetwork,
  "vesting": "5% At TGE, 5% after each 30 days for the next 19 months",
  "telegram": "https://t.me/brainstarterio",
  "twitter": "https://twitter.com/BrainStarterio",
  "website": "https://www.brainstarter.io/",
  "medium": "",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "",
  "openTime":1713938400,
  "closeTime":1714111200,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0xB70D18c5Cb03632283bc01F03dA8348086f3Ca92",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"30th April, Uniswap",
  "audit": false,
  "kyc": false,
  "fcfs":"1714093200",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":LEVELS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "protectionType":IDORULES[0],
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
},
{
  "params":"qubyai",
  "tokenName":"QUBY AI",
  "tokenSymbol":"QYAI",
  "tokenDecimals":18,
  "tokenAddress":"A6jb9PSPxhmy4hegFV2eSTk3WeVsnYVRxWTpMWKmUe21",
  "totalSupply":"101,000,000,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$1,200,000",
  "initialFDV":"TBA",
  "idoPrice":'0.000000035',
  "listingPrice":'0.000000050',
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/qubyai/logo.png",
  "bannerUrl": "/assets/images/project/qubyai/banner.png",
  "description":"Step into the extraordinary universe of QuBy AI, where blockchain brilliance meets imaginative innovation. In this journey, the captivating world of memes collides with the raw excitement of competitive levels. With the power of groundbreaking technology and the robust decentralization of the Solana network, QuBy AI is committed to sculpting a gaming odyssey that is as engaging as it is unique.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"70000",
  "softCap":"25000",
  "cap":69999.67,
  "saleType":PUBLIC,
  "blockchain":solNetwork,
  "vesting": "100% At TGE",
  "telegram": "https://t.me/QubyAi",
  "twitter": "https://twitter.com/QuByAigames",
  "website": "https://www.qubyai.com/",
  "medium": "",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "",
  "openTime":1713679200,
  "closeTime":1713852000,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0xCb6041DEE82BfFf329D304dc5af32aEFF5998165",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"27th April, Raydium, Orca, Bitmart",
  "audit": false,
  "kyc": false,
  "fcfs":"1713834000",
  "walletRegister":true,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":LEVELS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "protectionType":IDORULES[0],
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
},
/*
{
  "params":"starchain",
  "tokenName":"StarChain",
  "tokenSymbol":"STRC",
  "tokenDecimals":18,
  "tokenAddress":"0xe84031d4eDc573dca7Ba0a042Aeb76b8959E053D",
  "totalSupply":"500,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$100K",
  "initialFDV":"TBA",
  "idoPrice":0.01,
  "listingPrice":0.01,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/starchain/logo.png",
  "bannerUrl": "/assets/images/project/starchain/banner.png",
  "description":"StarChain is a pioneering crypto project dedicated to astronomical exploration and knowledge sharing. Leveraging the security, transparency, and efficiency of the Polygon blockchain, we offer unique platform for tracking and discovering astronomical objects. Our goal is to create a decentralized hub where the cosmos is no longer the exclusive domain of elite scientific institutions but a shared treasure trove open to exploration by the global community.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"80000",
  "softCap":"25000",
  "cap":0,
  "saleType":PUBLIC,
  "blockchain":polygonNetwork,
  "vesting": "30% At TGE, 10% weekly thereafter",
  "telegram": "https://t.me/StarChainDev",
  "twitter": "https://twitter.com/StarChainDev",
  "website": "https://starchain.dev/",
  "medium": "",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "",
  "openTime":"TBA",
  "closeTime":"TBA",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"TBA",
  "audit": false,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":LEVELS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "protectionType":IDORULES[0],
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
},
*/
{
  "params":"litcraft",
  "tokenName":"Fias",
  "tokenSymbol":"$Fias",
  "tokenDecimals":18,
  "tokenAddress":"0x1d64231E179f074BAA3f22d44a6Ca3a2670b2709",
  "totalSupply":"350,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$460,000",
  "initialFDV":"TBA",
  "idoPrice":0.08,
  "listingPrice":0.08,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"10000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/litcraft/logo.png",
  "bannerUrl": "/assets/images/project/litcraft/banner.jpg",
  "description":"Voted the 2nd best blockchain game by Cointelegraph - Join the LitCraft Metaverse, where you “Play To Own” your digital items and collectables! Play a variety of games from casual puzzles to competitive NFT battlers. Craft and battle your own unique LitPets or build your own virtual business and trade items! Available on PC, Android and iOS.",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"75000",
  "softCap":"25000",
  "cap":52499.00,
  "saleType":PUBLIC,
  "blockchain":ethNetwork,
  "vesting": "25% At TGE, then daily linear vesting for 2 months",
  "telegram": "https://t.me/FiasOfficialGroup",
  "twitter": "https://x.com/LitCraft_DFE",
  "website": "https://www.litcraft.com/",
  "medium": "",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "",
  "openTime":1717480800,
  "closeTime":1717653600,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x3c77CF2cB007a820fb0F63d9C87Da7bE2bF384c6",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"11th of June, MEXC",
  "audit": false,
  "kyc": false,
  "fcfs":"1717635600",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":LEVELS,
  "claimable":true,
  "vestingDetails":{"type":VESTINGTYPE[1],"claimAddress":"0xaF7b49CD5a439E45B6b0f00456af58F74e396aaE"},
  "distribution":DISTRIBUTION[1],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "protectionType":IDORULES[0],
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
},
{
  "params":"medicalveda",
  "tokenName":"MVEDA",
  "tokenSymbol":"MVEDA",
  "tokenDecimals":18,
  "tokenAddress":"HsLp6UGPEFDGLNw8gYHDmbwYoBQBv49JKgtzGZ52WhYm",
  "totalSupply":"880,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"TBA",
  "initialFDV":"$3,168,000",
  "idoPrice":0.0036,
  "listingPrice":0.0036,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/medicalveda/logo.png",
  "bannerUrl": "/assets/images/project/medicalveda/banner.png",
  "description":"MedicalVeda uses new cutting-edge technologies like Blockchain, Artificial Intelligence, and Machine learning. MedicalVeda is a futuristic way to manage health information and offer a Personalized Medicine Solution: AI analyzes genetic data to identify individual variations and tailor treatment plans based on a patient’s unique genetic makeup. Predictive analytics help forecast disease risks and personalized healthcare interventions",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"25000",
  "cap":29500,
  "saleType":PUBLIC,
  "blockchain":solNetwork,
  "vesting": "15% at TGE and 3 months vesting",
  "telegram": "https://t.me/Medicalvedacommunity",
  "twitter": "https://x.com/IncVeda",
  "website": "https://medicalveda.com/",
  "medium": "",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "",
  "openTime":1719208800,
  "closeTime":1719381600,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x9fA171731c3754bAC3CF0A4ABE54d12470c02ba2",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"27th of June, Raydium",
  "audit": false,
  "kyc": false,
  "fcfs":"1719363600",
  "walletRegister":true,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":LEVELS,
  "claimable":false,
  "vestingDetails":{"type":VESTINGTYPE[1],"claimAddress":""},
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "protectionType":IDORULES[1],
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
},

{
  "params":"tanukilaunchpad",
  "tokenName":"tanukilaunchpad",
  "tokenSymbol":"TANUPAD",
  "tokenDecimals":8,
  "tokenAddress":"7weXBXvBwFH3NZcth8oUYf6wgkLPYPNZzUJAhCMcqnHQ",
  "totalSupply":"2,000,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$500K",
  "initialFDV":"TBA",
  "idoPrice":0.007,
  "listingPrice":0.015,
  "ath":0,
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/tanuki/logo.png",
  "bannerUrl": "/assets/images/project/tanuki/banner.png",
  "description":"Tanuki Launchpad the first to support various blockchains like ETH, BNB.AVAX,Solana, Sui,Sei,Metis,ADA,and more,all in one place Join us in revolutionizing web3",
  "whitelist_url": "",
  "participent":"TBA",
  "hardCap":"70000",
  "softCap":"25000",
  "cap":15227.9,
  "saleType":PUBLIC,
  "blockchain":solNetwork,
  "vesting": "100% at TGE",
  "telegram": "http://t.me/Tanukilaunchpad",
  "twitter": "https://x.com/tanukilaunchpad",
  "website": "http://tanukilaunchpad.com",
  "medium": "",
  "discord": "",
  "instagram":"",
  "whitepaperUrl": "",
  "openTime":1721109600,
  "closeTime":1721113200,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0xaF6226ef3f6fD180C6Ac3228B6ce3F03dA606F25",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"22nd of July, Raydium, LBANK,XT, LATOKEN",
  "audit": false,
  "kyc": false,
  "fcfs":"",
  "walletRegister":true,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":FCFS,
  "claimable":false,
  "distribution":DISTRIBUTION[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
  "protectionType":IDORULES[1],
  "buyChart":false,
  "buyLinks":[{"icon":"","name":"Raydium","description":"Buy SOLX","link":""}],
  "chartLinks":[{"icon":"/assets/img/dextools.png","name":"DEXTools","link":""}]
},





{
  "params":"gpt360",
  "tokenName":"G360",
  "tokenSymbol":"G360",
  "tokenDecimals":9,
  "tokenAddress":"0x25134680375c9Ee5b95F3dB514eB450E608549a6",
  "totalSupply":"500,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$1,246,500",
  "initialFDV":"TBA",
  "idoPrice":0.02,
  "listingPrice":0.03,
  "ath":"TBA",
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/gpt360/logo.png",
  "bannerUrl": "/assets/images/project/gpt360/banner.png",
  "description":"GPT360, the revolutionary super app revolutionizing web3 marketing and networking. Our innovative features have proven their efficacy in driving user traction, revitalizing existing user communities, and fostering community momentum.",
  "whitepaperUrl": "",
  "participent":"TBA",
  "hardCap":"40000",
  "softCap":"15000",
  "cap":26000,
  "saleType":PRIVATE,
  "blockchain":bscNetwork,
  "vesting": "8% At TGE | 4m Cliff | 11m Linear Vesting with Daily Unlock",
  "telegram": "https://t.me/GPT360_Official",
  "twitter": "https://twitter.com/GPT360_Official",
  "website": "https://gpt360.io/",
  "linkedin": "",
  "medium": "https://medium.com/@gpt360",
  "reddit": "",
  "facebook": "",
  "discord": "https://discord.com/invite/Dz9bdQ95p5",
  "whitepaperUrl": "https://docs.gpt360.io/",
  "openTime":1721455200,
  "closeTime":1721628000,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0xcC8461c23519AAbfBC568a6DE00a089bC552Fcfd",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"31st of August, Gate, MEXC",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":PUBLIC,
  "distribution":DISTRIBUTION[1],
  "protectionType":IDORULES[2],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
},

{
  "params":"hhmc",
  "tokenName":"HHMC",
  "tokenSymbol":"HHMC",
  "tokenDecimals":9,
  "tokenAddress":"",
  "totalSupply":"1,000,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$650K",
  "initialFDV":"TBA",
  "idoPrice":0.0010,
  "listingPrice":0.002,
  "ath":"TBA",
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/hhmc/logo.png",
  "bannerUrl": "/assets/images/project/hhmc/banner.png",
  "description":"HHMC is WEB3 gaming studio with live games on Polygon, Google Play and Epic Store for Solana",
  "whitepaperUrl": "",
  "participent":"TBA",
  "hardCap":"25000",
  "softCap":"15000",
  "cap":0,
  "saleType":PRIVATE,
  "blockchain":solNetwork,
  "vesting": "20% At TGE, 4 months Linear vesting",
  "telegram": "",
  "twitter": "https://x.com/hhmc2021",
  "website": "https://hhmc.io/",
  "linkedin": "",
  "medium": "",
  "reddit": "",
  "facebook": "",
  "discord": "https://discord.com/invite/xzGxUC6hqH",
  "whitepaperUrl": "https://drive.google.com/file/d/1LYJwxR9dMhqaZWlRDKT-xkd3kcJocPqy/view",
  "openTime":"TBA",
  "closeTime":"TBA",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"4th of November, Mexc",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":true,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":PUBLIC,
  "distribution":DISTRIBUTION[0],
  "protectionType":IDORULES[2],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
},
{
  "params":"figcoin",
  "tokenName":"FIGCOIN",
  "tokenSymbol":"FIG",
  "tokenDecimals":18,
  "tokenAddress":"",
  "totalSupply":"1,000,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$240K",
  "initialFDV":"TBA",
  "idoPrice":0.01,
  "listingPrice":0.01,
  "ath":"TBA",
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/figcoin/logo.png",
  "bannerUrl": "/assets/images/project/figcoin/banner.png",
  "description":'FIG Coin is an AI-powered "play-to-earn" card game built on the Telegram platform. Users participate in the game by using FIG Coin, completing tasks, and earning rewards. The project aims to combine fun and profit in the crypto world, offering users an interactive and rewarding experience. FIG Coin also encourages users to invite friends, increasing participation and reward potential. Built on blockchain technology, FIG Coin provides a secure, transparent, and fair gaming environment for its users.',
  "whitepaperUrl": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"15000",
  "cap":0,
  "saleType":PRIVATE,
  "blockchain":bscNetwork,
  "vesting": "TGE %20 1M Cliff 6M Equal distribution",
  "telegram": "https://t.me/FIGCoinn",
  "twitter": "https://x.com/FIGCoinn",
  "website": "https://figcoin.io/",
  "linkedin": "",
  "medium": "",
  "reddit": "",
  "facebook": "",
  "discord": "",
  "whitepaperUrl": "https://figcoin.io/whitepaper.pdf",
  "openTime":"TBA",
  "closeTime":"TBA",
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0xBCb1CfDF839e917f31ECdaF7E86F86A53cf1C15B",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"17th of September, THENAFI,WEEX,BİNGX,KCEX,BİTGERT",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":PUBLIC,
  "distribution":DISTRIBUTION[1],
  "protectionType":IDORULES[2],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
},
{
  "params":"argybargy",
  "tokenName":"Argy Bargy Token",
  "tokenSymbol":"ARGY",
  "tokenDecimals":9,
  "tokenAddress":"ej12TDowxyD1WarS1ZPby1PMtgUNJC4hJY6F1s29YVs",
  "totalSupply":"3,000,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$151200",
  "initialFDV":"TBA",
  "idoPrice":0.014,
  "listingPrice":0.014,
  "ath":"TBA",
  "minAllocation":"50USDT",
  "maxAllocation":"5000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/argybargy/logo.png",
  "bannerUrl": "/assets/images/project/argybargy/banner.png",
  "description":'Argy Bargy is a next-level Web3 game ecosystem that’s all about blending fun, fitness, and the future. It’s got everything from a Match3 Collection Card RPG and an AR Battle game to the E.A.S.Y. Life app (that’s short for ‘Earn As You Step Lifestyle’) where you can literally make money with every step you take. Plus, the Argy Tap Bot on Telegram keeps the whole experience super interactive and connected. It’s not just a game- it’s a whole new way to live, play, and earn.',
  "whitepaperUrl": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"15000",
  "cap":42499,
  "saleType":PUBLIC,
  "blockchain":solNetwork,
  "vesting": "10% At TGE, then monthly vesting for 12 months",
  "telegram": "https://t.me/argybargy_io",
  "twitter": "https://x.com/argybargy_io",
  "website": "https://argybargy.io",
  "linkedin": "",
  "medium": "",
  "reddit": "",
  "facebook": "",
  "discord": "",
  "whitepaperUrl": "https://whitepaper.argybargy.io",
  "openTime":1727416800,
  "closeTime":1727503200,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x54e6a452D3F15cE4fF0Dc75cF6E3184Bf5a3a434",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"28th of September, Mexc, XT, P2B",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":true,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":PUBLIC,
  "distribution":DISTRIBUTION[1],
  "protectionType":IDORULES[1],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
},
{
  "params":"ivacay",
  "tokenName":"VacayCoin",
  "tokenSymbol":"VAC",
  "tokenDecimals":9,
  "tokenAddress":"",
  "totalSupply":"100,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$1,200,000",
  "initialFDV":"TBA",
  "idoPrice":0.40,
  "listingPrice":0.43,
  "ath":"TBA",
  "minAllocation":"50USDT",
  "maxAllocation":"2500USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/ivacay/logo.png",
  "bannerUrl": "/assets/images/project/ivacay/banner.png",
  "description":'iVaCay is redefining the future of travel and property ownership through SharedStays, a revolutionary blockchain-based platform. With iVaCay, users can stake tokens to own fractional shares of vacation properties and earn rewards from these properties’ rental income.',
  "whitepaperUrl": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"15000",
  "cap":51625,
  "saleType":PUBLIC,
  "blockchain":bscNetwork,
  "vesting": "40% At TGE, with the remaining 60% vested linearly over 6 months.",
  "telegram": "https://t.me/iVaCay",
  "twitter": "https://x.com/ivacay01",
  "website": "https://www.ivacay.io/",
  "linkedin": "",
  "medium": "",
  "reddit": "",
  "facebook": "",
  "discord": "",
  "whitepaperUrl": "https://www.dropbox.com/scl/fi/o05g3dqfcmyq1gfck7x9q/IVacay-Final-Litepaper.pdf?rlkey=oj2f3xpty3r4uydenvcrs5rb0&dl=0",
  "openTime":1733724000,
  "closeTime":1733896800,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x0Ccf2007f76117954ec4b189dfe8A38592De18a5",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"26th of December, PancakeSwap",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":PUBLIC,
  "distribution":DISTRIBUTION[1],
  "protectionType":IDORULES[0],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
},
{
  "params":"tokenbuilder",
  "tokenName":"TokenBuilder",
  "tokenSymbol":"TBAI",
  "tokenDecimals":9,
  "tokenAddress":"",
  "totalSupply":"100,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$2.250,000",
  "initialFDV":"TBA",
  "idoPrice":0.049,
  "listingPrice":0.049,
  "ath":"TBA",
  "minAllocation":"50USDT",
  "maxAllocation":"10000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/tokenbuilder/logo.png",
  "bannerUrl": "/assets/images/project/tokenbuilder/banner.jpg",
  "description":'TokenBuilder is a fair token offering ecosystem uniting a launchpad, research platform, AI agents, and project-building tools to enable more transparent token launches. Powered by specialized AI, it helps investors discover high-potential token launces while tackling challenges in token offerings. The Fair Token Offering (FTO) framework combines advanced AI with robust tools, empowering Web3 projects to launch tokens transparently and providing fairer opportunities for retail investors. From FTO Calendar listings to secondary market trading, TokenBuilder’s AI agents analyze market data, assess fundamentals, and offer real-time insights, setting new standards for project launches and investment discovery.',
  "whitepaperUrl": "",
  "participent":"TBA",
  "hardCap":"50000",
  "softCap":"15000",
  "cap":50000,
  "saleType":PUBLIC,
  "blockchain":baseNetwork,
  "vesting": "100% At TGE",
  "telegram": "https://t.me/tokenbuilderai",
  "twitter": "https://x.com/tokenbuilderai",
  "website": "https://www.tokenbuilder.ai/",
  "linkedin": "https://www.linkedin.com/company/tokenbuilder",
  "medium": "",
  "reddit": "",
  "facebook": "",
  "discord": "https://discord.gg/uwH3Mt9B6n",
  "whitepaperUrl": "https://docs.tokenbuilder.ai/",
  "openTime":1734933600,
  "closeTime":1735106400,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x08F103Db660a282DCee7b777934C20Ff82199BB5",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"2nd of January 2025, Uniswap",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":PUBLIC,
  "distribution":DISTRIBUTION[0],
  "protectionType":IDORULES[1],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
},
{
  "params":"vcw",
  "tokenName":"VCW",
  "tokenSymbol":"VCW",
  "tokenDecimals":9,
  "tokenAddress":"2su9ajvxnPtkJ4LncE4twQdBPkVRqBRsh954EKxf4SQy",
  "totalSupply":"300,000,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"150 million USDT",
  "initialFDV":"TBA",
  "idoPrice":0.0005,
  "listingPrice":0.0005,
  "ath":"TBA",
  "minAllocation":"100USDT",
  "maxAllocation":"10000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/vcw/logo.png",
  "bannerUrl": "/assets/images/project/vcw/banner.png",
  "description":'VCW is an auxiliary coin of the 9-year unicorn EHZ across all levels, systems, and ecosystems. At the same time, it is also an auxiliary coin, element coin, and universal coin driven by the value of all things in the world! Value-driven!',
  "whitepaperUrl": "",
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"15000",
  "cap":0,
  "saleType":PUBLIC,
  "blockchain":solNetwork,
  "vesting": "100% At TGE",
  "telegram": "https://t.me/vcwchannel",
  "twitter": "https://x.com/vcwlove",
  "website": "https://www.cjz.vip",
  "linkedin": "",
  "medium": "",
  "reddit": "",
  "facebook": "",
  "discord": "https://discord.gg/vcwlove",
  "whitepaperUrl": "",
  "openTime":1735452000,
  "closeTime":1735624800,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"0x474A381Ff562d9cECa09C5006010A9B46536A8C4",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"31st of December 2024, raydium",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":true,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":PUBLIC,
  "distribution":DISTRIBUTION[0],
  "protectionType":IDORULES[1],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
},
{
  "params":"updown",
  "tokenName":"UPMarket",
  "tokenSymbol":"UPD",
  "tokenDecimals":9,
  "tokenAddress":"",
  "totalSupply":"100,000,000",
  "initialSupply":"TBA",
  "initialMarketCap":"$5,000,000",
  "initialFDV":"TBA",
  "idoPrice":0.05,
  "listingPrice":0.05,
  "ath":"TBA",
  "minAllocation":"50USDT",
  "maxAllocation":"10000USDT",
  "currency":"USDT",
  "logoUrl": "/assets/images/project/updown/logo.png",
  "bannerUrl": "/assets/images/project/updown/banner.png",
  "description":'UpDown Platform offers an innovative approach to cryptocurrency price predictions. Players engage in real-time prediction games, including Crypto Prediction and Futures, competing directly against the market with options for leveraged bets.',
  "participent":"TBA",
  "hardCap":"100000",
  "softCap":"15000",
  "cap":0,
  "saleType":PUBLIC,
  "blockchain":baseNetwork,
  "vesting": "20% At TGE,1m clif 5m vesting",
  "telegram": "https://t.me/updown_market",
  "twitter": "https://x.com/updown_web3",
  "website": "https://updown.market",
  "linkedin": "",
  "medium": "",
  "reddit": "",
  "facebook": "",
  "discord": "",
  "whitepaperUrl": "https://risesoft.gitbook.io/upmarket",
  "openTime":1738044000,
  "closeTime":1738216800,
  "listingTime":0,
  "totalRaised":0,
  "saleAddress":"",
  "coingecko": "",
  "coinmarketcap": "",
  "listingDetails":"30th of January 2025, TBA",
  "audit": true,
  "kyc": false,
  "fcfs":"",
  "walletRegister":false,
  "whitelistRegister":false,
  "kycRegister":false,
  "openRegTime":"",
  "closeRegTime":"",
  "accessType":PUBLIC,
  "distribution":DISTRIBUTION[0],
  "protectionType":IDORULES[1],
  "baseAllocation":"",
  "currentPrice":"",
  "metamask":false,
},
];

//https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=polkafantasy,snook,bit-hotel,bullperks,bloktopia,blokpad,gensokishis-metaverse,sidus,nftpad,babylons,syndicate-2,the-husl,qanplatform,solchicks-token,netvrk,bullieverse,continuum-world





